import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface Props {
	agencyName: string;
	effectiveDate: string;
	pageName: string;
}

const FormHeader = (props: Props) => {
	return (
		<Row>
			<Col>
				<h1 className='h2'>{props.pageName}</h1>
				<p>Agency: {props.agencyName}</p>
				<p>
					As of: {new Date(props.effectiveDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}
				</p>
				<hr />
			</Col>
		</Row>
	);
};

export default FormHeader;
