import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import cn from 'classnames';
import TopNav from '../Components/TopNav';
import SideNav from '../Components/SideNav';

interface Props {
	children: ReactNode;
}

const AuthedLayout = (props: Props) => {
	const location = new URL(window.location.href).pathname.split('/');
	const valuationId =
		(location[1] == 'valuation-form' && parseInt(location[2]))
			? parseInt(location[2])
			: null;

	return (
		<Container fluid className='authed-layout'>
			<Row>
				{valuationId && (
					<Col
						md={2}
						className='sidebar-col bg-primary d-none d-md-flex'
					>
						<SideNav valuationId={valuationId} pageName={location[3]} pageIndex={location[4]} />
					</Col>
				)}
				<Col md={valuationId ? 10 : 12} className='main-col'>
					<TopNav />
					<Container fluid className='mt-10 body-container'>
						{props.children}
						{/* footer */}
						<div className='footer mt-10 mb-5'>
							<hr />
							<p className='copyright text-decoration-none'>
								© 2022 <a href='#'>IA Valuations.</a> All Rights
								Reserved.
							</p>
						</div>
					</Container>
				</Col>
			</Row>
		</Container>
	);
};

export default AuthedLayout;
