import React, { ReactNode, useEffect } from 'react';
import AuthedLayout from '../../Layouts/Authed';
import { Button, Form, Col, Table, Alert } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';
import Owner from '../../Data/Owner';
import Valuation from '../../Data/Valuation';
import OwnerRow from './Owners/OwnerRow';
import { replaceNullWithEmpty } from './Helpers/RepeatingForms';
import ReactSelect from 'react-select';
import FormHeader from '../../Components/Forms/FormHeader';

interface Props {
	valuation: Valuation;
	owners: Owner[];
}

interface FormData {
	owners: Partial<Owner>[];
	own_all_renewal_commissions: boolean;
	own_all_renewal_commissions_explain: string;
}

const OwnershipAndShares = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		owners: replaceNullWithEmpty(props.owners) ?? [],
		own_all_renewal_commissions:
			props.valuation.own_all_renewal_commissions,
		own_all_renewal_commissions_explain:
			props.valuation.own_all_renewal_commissions_explain ?? '',
	});

	const totalShares = data.owners.reduce(
		(total: number, currentValue: Partial<Owner>) => {
			return total + (currentValue.number_of_shares ?? 0);
		},
		0
	);

	const submit = (e: any) => {
		e.preventDefault();
		post(`/valuation-form/${props.valuation.id}/ownership`);
	};

	const updateTableValue = (event: any, index: number) => {
		let newData: any = { ...data };
		let value = event.target.value;
		if (event.target.type == 'number' && event.target.value != 0) {
			value = parseFloat(event.target.value) || '';
		}
		newData.owners[index][event.target.name] = value;
		setData(newData);
	};

	const addTableRow = () => {
		const newData = {
			...data,
			owners: [
				...data.owners,
				{
					id: '',
					first_name: '',
					last_name: '',
					age: '',
					number_of_shares: '',
				},
			] as Owner[],
		};
		setData(newData);
	};

	const removeTableRow = (index: number) => {
		const newData: any = { ...data };
		newData.owners.splice(index, 1);
		setData(newData);
	};

	const setOwnAllRenewalCommissions = (input: any) => {
		setData('own_all_renewal_commissions', input.value);
	};

	const setCommissionsExplain = (event: any) => {
		setData('own_all_renewal_commissions_explain', event.target.value);
	};

	useEffect(() => {
		if (data.owners.length == 0) {
			addTableRow();
		}
	}, [props]);

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Ownership and Shares'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th id='ownerNum' className='cell-xs sticky-cell'>
								#
							</th>
							<th id='first_name'>First Name</th>
							<th id='last_name'>Last Name</th>
							<th id='age'>Age</th>
							<th id='number_of_shares'>Number of Shares</th>
							<th id='equity'>Total Equity</th>
							<th id='delete_element'></th>
						</tr>
					</thead>
					<tbody>
						{data.owners.map(
							(owner: Partial<Owner>, index: number) => (
								<OwnerRow
									key={`owner-${index}`}
									owner={owner}
									totalShares={totalShares}
									index={index}
									errors={errors}
									updateRow={updateTableValue}
									deleteRow={removeTableRow}
								/>
							)
						)}
					</tbody>
				</Table>

				<div className='d-flex w-100 justify-content-between'>
					<Col md={3} className='d-flex justify-content-beginning'>
						<Form.Label>Total Shares:</Form.Label>
						<h4 className='mx-2'>{totalShares}</h4>
					</Col>
					<div className='d-flex justify-content-end'>
						<Button onClick={addTableRow} className='m-4'>
							Add Row
						</Button>
					</div>
				</div>
				<Form.Group className='py-4'>
					<Form.Label id='own_all_renewal_commissions_label'>
						Does the agency own all of the renewal commissions?
					</Form.Label>
					<Col md='4'>
						{errors.own_all_renewal_commissions && (
							<Alert variant='dkRed'>
								{errors.own_all_renewal_commissions}
							</Alert>
						)}
						<ReactSelect
							id='own_all_renewal_commissions'
							aria-labelledby='own_all_renewal_commissions_label'
							options={[
								{ label: 'Yes', value: true },
								{ label: 'No', value: false },
							]}
							onChange={setOwnAllRenewalCommissions}
							value={
								data.own_all_renewal_commissions !== null
									? {
											label:
												data.own_all_renewal_commissions ===
												true
													? 'Yes'
													: 'No',
											value: data.own_all_renewal_commissions,
									  }
									: {}
							}
							className='input'
						/>
					</Col>
				</Form.Group>
				{data.own_all_renewal_commissions === false && (
					<Form.Group className='py-4'>
						<Form.Label id='commissions_explain_label'>
							Please explain:
						</Form.Label>
						<Col md='6'>
							{errors.own_all_renewal_commissions_explain && (
								<Alert variant='dkRed'>
									{errors.own_all_renewal_commissions_explain}
								</Alert>
							)}
							<Form.Control
								id='commissions_explain'
								aria-labelledby='commissions_explain_label'
								as='textarea'
								rows={3}
								value={data.own_all_renewal_commissions_explain}
								onChange={setCommissionsExplain}
							/>
						</Col>
					</Form.Group>
				)}
				<div className='d-flex justify-content-end'>
					<Button
						type='submit'
						className='mt-4 ms-4'
						disabled={processing}
					>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

OwnershipAndShares.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default OwnershipAndShares;
