import React from 'react';
import { render } from 'react-dom';
import { createInertiaApp } from '@inertiajs/inertia-react';
import { InertiaProgress } from '@inertiajs/progress';

import '../sass/app.scss';

InertiaProgress.init();

createInertiaApp({
	resolve: (name) => require(`./Pages/${name}`),
	setup({ el, App, props }) {
		render(<App {...props} />, el);
	},
});
