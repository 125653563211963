import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

import Account from '../../../Data/Account';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import rowHasErrors from '../Helpers/RowHasErrors';

interface Props {
	account: Partial<Account>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: any, index: number) => void;
	deleteIndex: (index: number) => void;
}

const AccountRow = ({
	account,
	index,
	errors,
	updateIndex,
	deleteIndex,
}: Props) => {
	const arrayName = 'account';
	const errorArrayName = 'accounts';
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`account-${index + 1}`} className='row-num sticky-cell'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='name'
					index={index}
					value={account.name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='industry'
					index={index}
					value={account.industry}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='total_annual_premium'
					index={index}
					value={account.total_annual_premium}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='total_annual_agency_commission'
					index={index}
					value={account.total_annual_agency_commission}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='num_years_with_agency'
					index={index}
					value={account.num_years_with_agency}
					prefix={null}
					digits={0}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					negativeValues={false}
				/>
			</td>
			<td>
				<TextInput
					name='agency_producer'
					index={index}
					value={account.agency_producer}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='carrier'
					index={index}
					value={account.carrier}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default AccountRow;
