import React, { ReactNode, useEffect, useState } from 'react';
import AuthedLayout from '../../Layouts/Authed';
import { Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { Inertia } from '@inertiajs/inertia';
import { useForm, usePage } from '@inertiajs/inertia-react';
import Valuation from '../../Data/Valuation';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FormHeader from '../../Components/Forms/FormHeader';
import AgencyManagementSystemSoftware from '../../Data/AgencyManagmentSystemSoftware';
import AgencyManagementSystemVendor from '../../Data/AgencyManagmentSystemVendor';
import YesNoSelect from '../../Components/Forms/YesNoSelect';
import TextareaInput from '../../Components/Forms/TextareaInput';
import Checkbox from '../../Components/Forms/Checkbox';
import DecimalInput from '../../Components/Forms/DecimalInput';

interface Props {
	valuation: Valuation;
	agencyManagementSystemSoftware: AgencyManagementSystemSoftware;
	agencyManagementSystemVendor: AgencyManagementSystemVendor;
	amsSoftwares: AgencyManagementSystemSoftware[];
	amsVendors: AgencyManagementSystemVendor[];
}

interface FormData {
	recieved_lease_agreement_document: boolean;
	lease_arrangement_describe: string;
	has_producer_agreements: boolean;
	has_employment_agreements: boolean;
	paperless_agency: boolean;
	has_documented_perpetuation_plan: boolean;
	recieved_documented_perpetuation_plan: boolean;
	amsSoftware: number | null;
	amsSoftwareNewName: string;
	amsVendor: number | null;
	amsVendorNewName: string;
	personal_lines_split: number;
	commercial_lines_split: number;
	small_commercial_business_percent: number;
}

const AgencyInformation = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		recieved_lease_agreement_document:
			props.valuation.recieved_lease_agreement_document ?? '',
		lease_arrangement_describe:
			props.valuation.lease_arrangement_describe ?? '',
		has_producer_agreements: props.valuation.has_producer_agreements ?? '',
		has_employment_agreements: props.valuation.has_employment_agreements ?? '',
		paperless_agency: props.valuation.paperless_agency ?? '',
		has_documented_perpetuation_plan:
			props.valuation.has_documented_perpetuation_plan ?? '',
		recieved_documented_perpetuation_plan:
			props.valuation.recieved_documented_perpetuation_plan ?? '',
		amsSoftware: props.agencyManagementSystemSoftware
			? props.agencyManagementSystemSoftware.id
			: null,
		amsSoftwareNewName: '',
		amsVendor: props.agencyManagementSystemVendor
			? props.agencyManagementSystemVendor.id
			: null,
		amsVendorNewName: '',
		personal_lines_split: props.valuation.personal_lines_split ?? '',
		commercial_lines_split: props.valuation.commercial_lines_split ?? '',
		small_commercial_business_percent:
			props.valuation.small_commercial_business_percent ?? '',
	});

	const [amsSoftwareOptions, setAmsSoftwareOptions] = useState<
		{ label: string; value: number }[]
	>([]);
	const [amsSoftwareSelect, setAmsSoftwareSelect] = useState<{
		label: string;
		value: number;
	}>();
	const [amsVendorOptions, setAmsVendorOptions] = useState<
		{ label: string; value: number }[]
	>([]);
	const [amsVendorSelect, setAmsVendorSelect] = useState<{
		label: string;
		value: number;
	}>();

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/agency-information`);
	};

	const dataSetter = (name: keyof FormData) => (event: any) =>
		setData(name, event.target.value);

	const setPaperlessAgency = (input: any) => {
		setData('paperless_agency', input.value);
	};

	const setRecievedLeaseAgreementDocument = (event: any) => {
		setData(
			'recieved_lease_agreement_document',
			!data.recieved_lease_agreement_document
		);
	};

	const setHasDocumentedPerpetuationPlan = (input: any) => {
		setData('has_documented_perpetuation_plan', input.value);
	};

	const setRecievedDocumentedPerpetuationPlan = (event: any) => {
		setData(
			'recieved_documented_perpetuation_plan',
			!data.recieved_documented_perpetuation_plan
		);
	};

	const handleAmsVendorSelect = (input: any) => {
		if (input.__isNew__) {
			let newData = {
				...data,
				amsVendorNewName: input.label,
				amsVendor: null,
			};
			setData(newData);
		} else {
			let newData = {
				...data,
				amsVendorNewName: '',
				amsVendor: input.value,
			};
			setData(newData);
		}
	};

	const handleAmsSoftwareSelect = (input: any) => {
		if (input.__isNew__) {
			let newData = {
				...data,
				amsSoftwareNewName: input.label,
				amsSoftware: null,
			};
			setData(newData);
		} else {
			let newData = {
				...data,
				amsSoftwareNewName: '',
				amsSoftware: input.value,
			};
			setData(newData);
		}
	};

	useEffect(() => {
		const vendorOptions = props.amsVendors.map(
			(vendor: AgencyManagementSystemVendor, index: number) => {
				return { label: vendor.name, value: vendor.id };
			}
		);
		setAmsVendorOptions(vendorOptions);

		const softwareOptions = props.amsSoftwares.map(
			(software: AgencyManagementSystemSoftware, index: number) => {
				return { label: software.name, value: software.id };
			}
		);
		setAmsSoftwareOptions(softwareOptions);
	}, [props]);

	useEffect(() => {
		if(data.amsVendor !== null) {
			props.amsVendors.map(
				(vendor: AgencyManagementSystemVendor) => {
					if(vendor.id == data.amsVendor) {
						setAmsVendorSelect({label: vendor.name, value: vendor.id});
					}
				}
			);
		}

		if(data.amsSoftware !== null) {
			props.amsSoftwares.map(
				(software: AgencyManagementSystemSoftware) => {
					if(software.id == data.amsSoftware) {
						setAmsSoftwareSelect({label: software.name, value: software.id});
					}
				}
			);
		}

		scrollTo(0,0);
	}, [])

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Agency Information'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Row className='my-4'>
					<Col md={6}>
						<TextareaInput
							label='Please describe the agency building lease agreement:'
							name='lease_arrangement_describe'
							value={data.lease_arrangement_describe}
							onChange={dataSetter('lease_arrangement_describe')}
							errors={errors}
						/>
					</Col>
					<Col md={6}>
						<Checkbox
							label='I have recieved and uploaded the lease agreement document.'
							name='recieved_lease_agreement_document'
							value={data.recieved_lease_agreement_document}
							onChange={setRecievedLeaseAgreementDocument}
							errors={errors}
						/>
					</Col>
				</Row>
				<Row className='my-4'>
					<Col md={6}>
						<YesNoSelect
							name='paperless_agency'
							label='Is your agency paperless?'
							value={data.paperless_agency}
							onChange={setPaperlessAgency}
							errors={errors}
						/>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col>
						<Form.Label>
							What Agency Management System do you currently use?
						</Form.Label>
					</Col>
				</Row>
				<Row className='mb-4'>
					<Col md={6}>
						<Form.Label>Vendor:</Form.Label>
						<CreatableSelect
							name='agencyManagementSystemVendor'
							placeholder='Vendor'
							value={amsVendorSelect}
							onChange={handleAmsVendorSelect}
							options={amsVendorOptions}
							className='input'
						/>
					</Col>
					<Col md={6}>
						<Form.Label>Software / System:</Form.Label>
						<CreatableSelect
							name='agencyManagementSystemSoftware'
							placeholder='Software / System'
							value={amsSoftwareSelect}
							onChange={handleAmsSoftwareSelect}
							options={amsSoftwareOptions}
							className='input'
						/>
					</Col>
				</Row>
				<Row className='my-4'>
					<Col md={6}>
						<YesNoSelect
							name='has_documented_perpetuation_plan'
							label='Do you have a documented perpetuation plan?'
							value={data.has_documented_perpetuation_plan}
							onChange={setHasDocumentedPerpetuationPlan}
							errors={errors}
						/>
					</Col>
					<Col md={6}>
						<Checkbox
							label='I have recieved and uploaded the perpetuation plan document.'
							name='recieved_documented_perpetuation_plan'
							value={data.recieved_documented_perpetuation_plan}
							onChange={setRecievedDocumentedPerpetuationPlan}
							errors={errors}
						/>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col>
						<Form.Label>
							What is your personal lines / commercial lines
							split?
						</Form.Label>
					</Col>
				</Row>
				<Row className='mb-4'>
					<Col md={6}>
						<Form.Label>Personal Lines:</Form.Label>
						<DecimalInput
							name='personal_lines_split'
							value={data.personal_lines_split}
							onChange={dataSetter('personal_lines_split')}
							errors={errors}
							prefix={null}
							postfix='%'
							arrayName='valuation.personal_lines_split'
							errorArrayName='valuation.personal_lines_split'
							overrideArrayName={true}
							overrideErrorArrayName={true}
						/>
					</Col>
					<Col md={6}>
						<Form.Label>Commercial Lines:</Form.Label>
						<DecimalInput
							name='commercial_lines_split'
							value={data.commercial_lines_split}
							onChange={dataSetter('commercial_lines_split')}
							errors={errors}
							prefix={null}
							postfix='%'
							arrayName='commercial_lines_split'
							errorArrayName='commercial_lines_split'
							overrideArrayName={true}
							overrideErrorArrayName={true}
						/>
					</Col>
				</Row>
				<Row className='my-4'>
					<Col md={6}>
						<Form.Label>
							Of your commercial lines, what percentage is small
							commercial business?
						</Form.Label>
						<DecimalInput
							name='small_commercial_business_percent'
							value={data.small_commercial_business_percent}
							onChange={dataSetter(
								'small_commercial_business_percent'
							)}
							errors={errors}
							prefix={null}
							postfix='%'
							arrayName='small_commercial_business_percent'
							errorArrayName='small_commercial_business_percent'
							overrideArrayName={true}
							overrideErrorArrayName={true}
						/>
					</Col>
				</Row>
				<div className='d-flex justify-content-end'>
					<Button
						type='submit'
						disabled={processing}
						className='mt-4 ms-4'
					>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

AgencyInformation.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default AgencyInformation;
