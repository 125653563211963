import React, { ReactNode, useEffect, useState } from 'react';
import AuthedLayout from '../../Layouts/Authed';
import { Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { Inertia } from '@inertiajs/inertia';
import { useForm, usePage } from '@inertiajs/inertia-react';
import { getAllCorporateStructures } from '../../Data/CorporateStructure';
import Valuation from '../../Data/Valuation';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface Props {
	valuation: Valuation;
	agencies?: any;
}

const Basics = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm({
		agency_name: props.valuation.agency_name
			? props.valuation.agency_name
			: '',
		agency_year_founded: props.valuation.agency_year_founded
			? props.valuation.agency_year_founded
			: '',
		agency_corporate_structure: props.valuation.agency_corporate_structure
			? props.valuation.agency_corporate_structure
			: '',
		effective_date: props.valuation.effective_date
			? props.valuation.effective_date.toLocaleString()
			: '',
		id: props.valuation.id ? props.valuation.id : '',
		agency_id: props.valuation.agency_id ? props.valuation.agency_id : '',
	});

	const submit = (e: any) => {
		e.preventDefault();
		const url = props.valuation.id
			? `/valuation-form/${props.valuation.id}/basics`
			: '/valuation-form/basics';
		Inertia.post(url, data);
	};

	const setAgencyName = (inputValue: any) => {
		setData((data) => ({ ...data, agency_name: inputValue.label }));
		if (!isNaN(Number(inputValue.value))) {
			setData((data) => ({ ...data, agency_id: inputValue.value }));
		}
		setAgencySelect(inputValue);
	};

	const setCorporateStructure = (inputValue: any) => {
		setData('agency_corporate_structure', inputValue.label);
		setCorpStructureSelect(inputValue);
	};

	const [agencies, setAgencies]: any = useState();
	const [agencySelect, setAgencySelect]: any = useState();
	const [corpStructureOptions, setCorpStructureOptions]: any = useState();
	const [corpStructureSelect, setCorpStructureSelect]: any = useState();

	useEffect(() => {
		let formattedAgencies = Object.keys(props.agencies).map((val: any) => {
			return { value: props.agencies[val], label: val };
		});
		setAgencies(formattedAgencies);
		let formattedCorpStructure = getAllCorporateStructures();
		setCorpStructureOptions(formattedCorpStructure);

		if (props.valuation.id) {
			setCorporateStructure({
				label: data.agency_corporate_structure,
				value: data.agency_corporate_structure,
			});
			setAgencyName({ label: data.agency_name, value: data.agency_id });
		}

		scrollTo(0,0);
	}, []);

	return (
		<div>
			<Row>
				<Col>
					<h1 className='h2'>Basics</h1>
					<hr />
				</Col>
			</Row>

			<Form onSubmit={submit} className='valuationForm'>
				<Row>
					<Form.Group as={Col} md={6} className='py-4'>
						<Form.Label htmlFor='agency_name'>
							Agency name:
						</Form.Label>
						{errors.agency_name && (
							<Alert variant='dkRed'>{errors.agency_name}</Alert>
						)}
						<CreatableSelect
							name='agency_name'
							placeholder='Agency Name'
							value={agencySelect}
							onChange={setAgencyName}
							options={agencies}
							className='input'
						/>
					</Form.Group>
					<Form.Group as={Col} md={6} className='py-4'>
						<Form.Label htmlFor='agency_year_founded'>
							Agency year founded:
						</Form.Label>
						{errors.agency_year_founded && (
							<Alert variant='dkRed'>
								{errors.agency_year_founded}
							</Alert>
						)}
						<Form.Control
							type='number'
							id='agency_year_founded'
							className='input'
							value={data.agency_year_founded}
							onChange={(e) =>
								setData('agency_year_founded', e.target.value)
							}
						/>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group as={Col} md={6} className='py-4'>
						<Form.Label htmlFor='effective_date'>
							Valuation effective date:
						</Form.Label>
						{errors.effective_date && (
							<Alert variant='dkRed'>
								{errors.effective_date}
							</Alert>
						)}
						<Form.Control
							type='date'
							id='effective_date'
							className='input'
							value={data.effective_date}
							onChange={(e) =>
								setData('effective_date', e.target.value)
							}
						/>
					</Form.Group>
					<Form.Group as={Col} md={6} className='py-4'>
						<Form.Label htmlFor='agency_corporate_structure'>
							Agency corporate structure:
						</Form.Label>
						{errors.agency_corporate_structure && (
							<Alert variant='dkRed'>
								{errors.agency_corporate_structure}
							</Alert>
						)}
						<ReactSelect
							options={corpStructureOptions}
							onChange={setCorporateStructure}
							value={corpStructureSelect}
							className='input'
						/>
					</Form.Group>
				</Row>

				<Form.Group className='py-4 d-flex justify-content-end'>
					<Button type='submit' disabled={processing}>
						Next
					</Button>
				</Form.Group>
			</Form>
		</div>
	);
};

Basics.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default Basics;
