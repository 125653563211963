// return only positive values
const onlyPositive = (
	event: React.FocusEvent<any>,
) => {
	if (event.target.value) {
		event.target.value = Math.abs(event.target.value);
	}
};

export default onlyPositive;
