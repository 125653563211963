// react to onChange event by grabbing the correct value from
// a number of input types
export const getEventValue = (event: React.ChangeEvent<any>) => {
	let value = event.target.value;
	if (event.target.type === 'number') {
		value = parseFloat(event.target.value);
		if (isNaN(value)) {
			value = '';
		}
	}

	if (event.target.type == 'checkbox') value = event.target.checked;
	return value;
};

// convert null values in array of objects
export const replaceNullWithEmpty = (array: Array<any>) => {
	if (array == null) return [];
	array.forEach((item) => {
		Object.keys(item).forEach(function (key) {
			if (item[key] == null) {
				item[key] = '';
			}
		});
	});
	return array;
};
