import React from 'react';
import { Form } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import ReactSelect from 'react-select';

interface Props {
	name: string;
	label?: string;
	value: string | undefined;
	onChange: (event: any) => void;
	errors: Errors & ErrorBag;
}

const TextareaInput = ({
    name,
    label,
    value,
    onChange,
    errors,
}: Props) => {
	return (
		<Form.Group>
			{label && <Form.Label>{label}</Form.Label>}
            {errors[`${name}`] && (
				<Form.Control.Feedback type='invalid'>
					{errors[`${name}`]}
				</Form.Control.Feedback>
			)}
				<Form.Control
					id={name}
					as='textarea'
					rows={3}
					value={value}
					onChange={(event) => onChange(event)}
				/>
		</Form.Group>
	);
};

export default TextareaInput;