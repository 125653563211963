const calculatePercentage = (
	portion: number,
	total: number,
	withFormatting: boolean = true
) => {
	const percent = total > 0 ? (portion / total) : 0;

	if (!withFormatting) return percent;

	const formattedPercent = new Intl.NumberFormat('en-US', {
		style: 'percent',
		currencySign: 'accounting',
		minimumFractionDigits: 0,
		maximumFractionDigits: 1,
	}).format(Math.abs(percent));

	return percent < 0 ? `(${formattedPercent})` : formattedPercent;
};

export default calculatePercentage;
