import { useForm } from '@inertiajs/inertia-react';
import React, { ReactNode } from 'react';
import { Button, Card, Form, Alert } from 'react-bootstrap';
import GuestLayout from '../../Layouts/Guest';

interface Props {
	status?: string;
}

const ForgotPassword = (props: Props) => {
	const { data, setData, post, processing, errors, reset } = useForm({
		email: '',
	});

	const submit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		post('/forgot-password');
	};

	return (
		<div className='vh-100 d-flex flex-column justify-content-center align-items-center'>
			<Card className='login-card p-3'>
				<Card.Body>
					{props.status && <Alert className='mb-3'>{props.status}</Alert>}
					<p className='mb-4'>
						Enter your email and we'll send you a password reset
						link.
					</p>
					<Form onSubmit={submit}>
						<Form.Group controlId='email' className='mb-3'>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type='email'
								value={data.email}
								onChange={(e) =>
									setData('email', e.target.value)
								}
							/>
							<div className='mt-2 text-dkRed'>{errors.email}</div>
						</Form.Group>
						<Button
							variant='primary'
							type='submit'
							disabled={processing}
							className='mt-3 w-100'
						>
							Send Password Reset Link
						</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
};

ForgotPassword.layout = (page: ReactNode) => <GuestLayout>{page}</GuestLayout>;

export default ForgotPassword;
