import React, { ReactNode, useEffect } from 'react';
import {
	Form,
	Button,
	Table,
	Row,
	Col,
	Alert,
	InputGroup,
} from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import PcCarrier from '../../Data/PcCarrier';
import Valuation from '../../Data/Valuation';
import PcCarrierRow from './PcCarriers/PcCarrierRow';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import { range } from '../../utils';
import PcCarrierOverridesRow from './PcCarriers/PcCarrierOverridesRow';
import formatDigits from './Helpers/FormatDigits';
import FormHeader from '../../Components/Forms/FormHeader';
import formatCurrency from './Helpers/FormatCurrency';

export interface PcCarrierAdditionalInfo {
	overrides: Record<number, number | null>;
}

interface Props {
	valuation: Valuation;
	pcCarriers: (PcCarrier & PcCarrierAdditionalInfo)[];
	maxPcCarriersPerValuation: number;
	currentYear: number;
}

interface FormData {
	annual_total_premium: number;
	annual_total_commission: number;
	pcCarriers: (Partial<PcCarrier> & Partial<PcCarrierAdditionalInfo>)[];
}

const PcCarriers = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		annual_total_premium: props.valuation.annual_total_premium ?? '',
		annual_total_commission: props.valuation.annual_total_commission ?? '',
		pcCarriers: replaceNullWithEmpty(props.pcCarriers) ?? [],
	});

	useEffect(() => {
		if (data.pcCarriers.length == 0) {
			addRow();
		}
	}, [props]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/pc-carriers`);
	};

	const addRow = () => {
		setData({
			...data,
			pcCarriers: [
				...data.pcCarriers,
				{
					id: '',
					carrier_name: '',
					total_annual_premium: '',
					annual_agency_commission: '',
					overrides: [] as PcCarrierAdditionalInfo[],
				},
			] as PcCarrier[],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData: any = { ...data };
		newData.pcCarriers[index][event.target.name] = getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData = { ...data };
		newData.pcCarriers.splice(index, 1);
		setData(newData);
	};

	const updateOverride = (
		index: number,
		year: string,
		event: React.ChangeEvent<any>
	) => {
		const newData: any = { ...data };
		newData.pcCarriers[index].overrides[year] = getEventValue(event);
		setData(newData);
	};

	const setAnnualTotalPremium = (event: React.ChangeEvent<any>) => {
		setData('annual_total_premium', event.target.value);
	};

	const setAnnualTotalCommission = (event: React.ChangeEvent<any>) => {
		setData('annual_total_commission', event.target.value);
	};

	const percentCommissions = data.annual_total_premium > 0
		? ((data.annual_total_commission / data.annual_total_premium) * 100).toFixed(2)
		: 0;

	const summedTotalAnnualPrem = data.pcCarriers.reduce(
		(accumulator, currentValue) =>
			accumulator + (currentValue?.total_annual_premium || 0),
		0
	);

	const summedAnnualAgencyComm = data.pcCarriers.reduce(
		(accumulator, currentValue) =>
			accumulator + (currentValue?.annual_agency_commission || 0),
		0
	);

	const totalPremComparison =
		data.annual_total_premium > 0
			? (
					(summedTotalAnnualPrem / data.annual_total_premium) *
					100
			  ).toFixed(2)
			: 0;

	const totalCommComparison =
		data.annual_total_commission > 0
			? (
					(summedAnnualAgencyComm / data.annual_total_commission) *
					100
			  ).toFixed(2)
			: 0;

	const yearTotal = (year: number) =>
		formatCurrency(
			data.pcCarriers.reduce(
				(accumulator, currentValue) =>
					accumulator + (currentValue?.overrides?.[year] || 0),
				0
			)
		);

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Top 5 P&C Carriers'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Row>
					<Col>
						<Form.Group className='py-4' as={InputGroup}>
							<Form.Label
								id='annual_total_premium_label'
								className='w-50 px-2'
							>
								What is the estimated Annual Total Premium
								Dollar amount currently written?
							</Form.Label>
							<InputGroup.Text>$</InputGroup.Text>
							<Form.Control
								id='annual_total_premium'
								aria-labelledby='annual_total_premium_label'
								onBlur={(event) => {
									formatDigits(event, 2);
									setAnnualTotalPremium(event);
								}}
								onChange={setAnnualTotalPremium}
								type='number'
								value={data.annual_total_premium}
							/>
							{errors.annual_total_premium && (
								<Alert variant='dkRed'>
									{errors.annual_total_premium}
								</Alert>
							)}
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group className='py-4' as={InputGroup}>
							<Form.Label
								id='annual_total_commission_label'
								className='w-50 px-2'
							>
								What is the estimated Annual Total Commission &
								Fees Dollar amount currently written?
							</Form.Label>
							<InputGroup.Text>$</InputGroup.Text>
							<Form.Control
								id='annual_total_commission'
								aria-labelledby='annual_total_commission_label'
								onChange={setAnnualTotalCommission}
								onBlur={(event) => {
									formatDigits(event, 2);
									setAnnualTotalCommission(event);
								}}
								type='number'
								value={data.annual_total_commission}
							/>
							{errors.annual_total_commission && (
								<Alert variant='dkRed'>
									{errors.annual_total_commission}
								</Alert>
							)}
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<p>
							Percentage of commissions and fees in relation to
							total premiums:{' '}
							<span className='fw-bold'>{percentCommissions}%</span>
						</p>
					</Col>
				</Row>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='pcCarrierNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='carrier_name'>Carrier Name</th>
							<th id='total_annual_premium'>
								Total Annual Premium
							</th>
							<th id='percent_of_total_agency_premiums'>
								Percent Of Total Agency Premiums
							</th>
							<th id='annual_agency_commission'>
								Annual Agency Commission
							</th>
							<th id='percent_of_total_agency_commissions'>
								Percent Of Total Agency Commissions
							</th>
							<th id='delete_element'></th>
						</tr>
					</thead>
					<tbody>
						{data.pcCarriers.map((pcCarrier, index) => (
							<PcCarrierRow
								key={`pcCarrier-${index}`}
								pcCarrier={pcCarrier}
								index={index}
								errors={errors}
								updateIndex={updateIndex}
								deleteIndex={deleteIndex}
								annual_total_premium={data.annual_total_premium}
								annual_total_commission={
									data.annual_total_commission
								}
							/>
						))}
					</tbody>
					<tfoot>
						<tr>
							<th />
							<th />
							<th>{formatCurrency(summedTotalAnnualPrem)}</th>
							<th>{totalPremComparison}%</th>
							<th>{formatCurrency(summedAnnualAgencyComm)}</th>
							<th>{totalCommComparison}%</th>
							<th />
						</tr>
					</tfoot>
				</Table>

				<h4 className='mt-2'>Contingent Dollars Received by Year</h4>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='pcCarrierOverrideNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='carrier_name_override'>Carrier Name</th>
							{range(
								props.currentYear - 5,
								props.currentYear
							).map((year) => (
								<th key={year}>{year}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.pcCarriers.map((pcCarrier, index) => (
							<PcCarrierOverridesRow
								key={`pcCarrier-overrides-${index}`}
								currentYear={props.currentYear}
								pcCarrier={pcCarrier}
								index={index}
								errors={errors}
								updateOverride={updateOverride}
							/>
						))}
					</tbody>
					<tfoot>
						<tr>
							<th />
							<th />
							{range(
								props.currentYear - 5,
								props.currentYear
							).map((year) => (
								<th key={year}>{yearTotal(year)}</th>
							))}
						</tr>
					</tfoot>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button
						onClick={addRow}
						disabled={
							data.pcCarriers.length >=
							props.maxPcCarriersPerValuation
						}
						className='mt-4'
					>
						Add Row
					</Button>
					<Button
						type='submit'
						disabled={processing}
						className='mt-4 ms-4'
					>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

PcCarriers.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default PcCarriers;
