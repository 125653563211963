import React from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { Link, usePage, useForm } from '@inertiajs/inertia-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import Logo from '../../images/logo.png';
import { Role } from '../Data/Role';

interface ToggleProps {
	children: any;
	onClick: any;
}

// override classes assigned to the toggle but keep the scripting
const CustomToggle = React.forwardRef(
	({ children, onClick }: ToggleProps, ref: any) => (
		<a
			href=''
			className='nav-link user-profile-icon'
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</a>
	)
);
CustomToggle.displayName = 'CustomToggle';

const TopNav = () => {
	const { user } = usePage<any>().props;
	const { post } = useForm();

	const userDropdown = (
		<Dropdown align='end'>
			<Dropdown.Toggle as={CustomToggle}>
				<FontAwesomeIcon icon={faUser} className='text-dkRed' />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item onClick={() => post('/logout')}>
					Sign Out
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);

	return (
		<Navbar expand='lg' className='top-nav align-items-center py-0'>
			<Navbar.Brand href='/'>
				<img
					src={Logo}
					className='d-inline-block align-top'
					alt='IA Valuations Logo'
				/>
			</Navbar.Brand>
			{user && (
				<div className='d-lg-none ms-auto pe-2'>{userDropdown}</div>
			)}
			<Navbar.Toggle aria-controls='mobile-nav' />
			<Navbar.Collapse id='mobile-nav'>
				<Nav className='w-100 justify-content-between align-items-center'>
					<div className='d-flex flex-column flex-lg-row align-items-center'>
						{user.role == Role.admin && (
							<Nav.Link href='/admin'>Admin</Nav.Link>
						)}
					</div>
					<div className='d-flex flex-column flex-lg-row align-items-center'>
						{user && (
							<div className='d-none d-lg-flex'>
								{userDropdown}
							</div>
						)}
					</div>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default TopNav;
