import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import Account from '../../Data/Account';
import Valuation from '../../Data/Valuation';
import AccountRow from './Accounts/AccountRow';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import FormHeader from '../../Components/Forms/FormHeader';

interface Props {
	valuation: Valuation;
	accounts: Account[];
	maxAccountsPerValuation: number;
}

interface FormData {
	accounts: Partial<Account>[];
}

const Accounts = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		accounts: replaceNullWithEmpty(props.accounts) ?? [],
	});

	useEffect(() => {
		if (data.accounts.length == 0) {
			addRow();
		}
	}, [props.accounts]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/accounts`);
	};

	const addRow = () => {
		setData({
			...data,
			accounts: [
				...data.accounts,
				{
					id: null,
					name: '',
					industry: '',
					total_annual_premium: 0,
					total_annual_agency_commission: 0,
					num_years_with_agency: 0,
					agency_producer: '',
					carrier: '',
				},
			] as Account[],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData: any = { ...data };
		newData.accounts[index][event.target.name] = getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData: any = { ...data };
		newData.accounts.splice(index, 1);
		setData(newData);
	};

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Top Ten Accounts'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th id='accountNum' className='cell-xs sticky-cell'>
								#
							</th>
							<th id='name'>Account Name</th>
							<th id='industry'>Industry</th>
							<th id='total_annual_premium'>
								Total Annual Premium $'s
							</th>
							<th id='total_annual_agency_commission'>
								Total Annual Agency Commission $'s
							</th>
							<th id='num_years_with_agency'>
								# Years With Agency
							</th>
							<th id='agency_producer'>Agency Producer</th>
							<th id='carrier'>Carrier</th>
							<th id='delete_element'></th>
						</tr>
					</thead>
					<tbody>
						{data.accounts.map(
							(account: Partial<Account>, index: number) => (
								<AccountRow
									key={`account-${index}`}
									account={account}
									index={index}
									errors={errors}
									updateIndex={updateIndex}
									deleteIndex={deleteIndex}
								/>
							)
						)}
					</tbody>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button
						onClick={addRow}
						disabled={
							data.accounts.length >=
							props.maxAccountsPerValuation
						}
						className='mt-4'
					>
						Add Row
					</Button>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

Accounts.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default Accounts;
