import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table, Alert, Row, Col } from 'react-bootstrap';
import { usePage, useForm } from '@inertiajs/inertia-react';

import Valuation from '../../../Data/Valuation';
import PlCategory from '../../../Data/PlCategory';
import PlSubcategory from '../../../Data/PlSubcategory';
import SubcategoryTable from './SubcategoryTable';
import ProFormaTable from './ProFormaTable';
import AuthedLayout from '../../../Layouts/Authed';
import { replaceNullWithEmpty } from '../Helpers/RepeatingForms';
import formatCurrency from '../Helpers/FormatCurrency';
import calculatePercentage from '../Helpers/CalculatePercentage';
import FormHeader from '../../../Components/Forms/FormHeader';
import { PlCategoryType } from '../../../Data/PlCategoryType';
import ThreeYearBreakdown from '../../../Data/ThreeYearBreakdown';
import CategorySummaryTable from './CategorySummaryTable';
import CategoryTable from './CategoryTable';

interface Props {
	valuation: Valuation;
	category: PlCategory;
	subcategories: PlSubcategory[];
	currentYear: number;
	otherCategoriesNet: ThreeYearBreakdown;
	otherCategoriesProForma: number;
}

export interface FormData {
	subcategories: PlSubcategory[];
}

const CategoryForm = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		subcategories: props.subcategories.map((subcategory) => {
			return {
				...subcategory,
				pl_financials:
					replaceNullWithEmpty(subcategory.pl_financials) ?? [],
				pl_pro_formas:
					replaceNullWithEmpty(subcategory.pl_pro_formas) ?? [],
			};
		}),
	});

	const submit = (event: React.FormEvent<any>) => {
		event.preventDefault();
		post(
			`/valuation-form/${props.valuation.id}/pl-category/${props.category.id}`,
			{
				preserveState: false,
			}
		);
	};

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName={props.category.name}
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<CategoryTable 
					category={props.category}
					currentYear={props.currentYear}
					data={data}
					dataSubcategories={data.subcategories}
					errors={errors}
					otherCategoriesNet={props.otherCategoriesNet}
					otherCategoriesProForma={props.otherCategoriesProForma}
					setData={setData}
				/>

				<div className='d-flex justify-content-end'>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

CategoryForm.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default CategoryForm;
