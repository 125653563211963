import React, { ReactNode } from 'react';
import { Row, Col, Table } from 'react-bootstrap';

import Valuation from '../../../Data/Valuation';
import ThreeYearBreakdown from '../../../Data/ThreeYearBreakdown';
import PlCategory from '../../../Data/PlCategory';
import AuthedLayout from '../../../Layouts/Authed';
import FormHeader from '../../../Components/Forms/FormHeader';
import formatCurrency from '../Helpers/FormatCurrency';
import calculatePercentage from '../Helpers/CalculatePercentage';
import { faRows } from '@fortawesome/pro-regular-svg-icons';

interface Props {
	netRevenue: number;
	netISA?: number;
	netProForma?: number;
	totalExpenses: number;
	totalISA?: number;
	totalProForma?: number;
	preTaxProfit: number;
	preTaxISA?: number;
	preTaxProForma?: number;
	ebitda: number;
	ebitdaISA?: number;
	ebitdaProForma?: number;
	showProFormaColumns?: boolean;
}

interface Row {
	name: string;
	actual: number;
	isa?: number;
	proForma?: number;
}

const OverallYearSummary = ({
	netRevenue,
	netISA,
	netProForma,
	totalExpenses,
	totalISA,
	totalProForma,
	preTaxProfit,
	preTaxISA,
	preTaxProForma,
	ebitda,
	ebitdaISA,
	ebitdaProForma,
	showProFormaColumns = false,
}: Props) => {
	const rows: Row[] = [
		{
			name: 'Net Revenue',
			actual: netRevenue,
			isa: netISA,
			proForma: netProForma,
		},
		{
			name: 'Total Expenses',
			actual: totalExpenses,
			isa: totalISA,
			proForma: totalProForma,
		},
		{
			name: 'Pre-tax Profit',
			actual: preTaxProfit,
			isa: preTaxISA,
			proForma: preTaxProForma,
		},
		{
			name: 'EBITDA',
			actual: ebitda,
			isa: ebitdaISA,
			proForma: ebitdaProForma,
		},
	];

	return (
		<Table striped bordered hover responsive>
			<thead>
				<tr>
					<th></th>
					<th>Actual</th>
					<th>% of Net Revenues</th>
					{showProFormaColumns && (
						<>
							<th>Income Statement Adjustments</th>
							<th>Pro Forma</th>
							<th>% of Net Revenues</th>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				{rows.map((row) => (
					<tr key={row.name}>
						<td>{row.name}</td>
						<td>{formatCurrency(row.actual)}</td>
						<td>{calculatePercentage(row.actual, netRevenue)}</td>
						{showProFormaColumns && (
							<>
								<td>{formatCurrency(row.isa ?? 0)}</td>
								<td>{formatCurrency(row.proForma ?? 0)}</td>
								<td>
									{calculatePercentage(
										row.proForma ?? 0,
										netProForma ?? 1
									)}
								</td>
							</>
						)}
					</tr>
				))}
			</tbody>
		</Table>
	);
};

OverallYearSummary.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default OverallYearSummary;
