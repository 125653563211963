import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import LhCarrier from '../../Data/LhCarrier';
import Valuation from '../../Data/Valuation';
import LhCarrierRow from './LhCarriers/LhCarrierRow';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import { range } from '../../utils';
import LhCarrierOverridesRow from './LhCarriers/LhCarrierOverridesRow';
import FormHeader from '../../Components/Forms/FormHeader';
import formatCurrency from './Helpers/FormatCurrency';

export interface LhCarrierAdditionalInfo {
	overrides: Record<number, number | null>;
}

interface Props {
	valuation: Valuation;
	lhCarriers: (LhCarrier & LhCarrierAdditionalInfo)[];
	maxLhCarriersPerValuation: number;
	currentYear: number;
}

interface FormData {
	lhCarriers: (Partial<LhCarrier> & LhCarrierAdditionalInfo)[];
}

const LhCarriers = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		lhCarriers: replaceNullWithEmpty(props.lhCarriers) ?? [],
	});

	useEffect(() => {
		if (data.lhCarriers.length == 0) {
			addRow();
		}
	}, [props.lhCarriers]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/lh-carriers`);
	};

	const addRow = () => {
		setData({
			...data,
			lhCarriers: [
				...data.lhCarriers,
				{
					carrier_name: '',
					total_annual_premium: 0,
					annual_agency_commission: 0,
					overrides: [],
				},
			],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.lhCarriers[index][event.target.name] = getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.lhCarriers.splice(index, 1);
		setData(newData);
	};

	const updateOverride = (
		index: number,
		year: string,
		event: React.ChangeEvent<any>
	) => {
		const newData: any = { ...data };
		newData.lhCarriers[index].overrides[year] = getEventValue(event);
		setData(newData);
	};

	const summedTotalAnnualPrem = data.lhCarriers.reduce(
		(accumulator, currentValue) =>
			accumulator + (currentValue?.total_annual_premium || 0),
		0
	);

	const summedAnnualAgencyComm = data.lhCarriers.reduce(
		(accumulator, currentValue) =>
			accumulator + (currentValue?.annual_agency_commission || 0),
		0
	);

	const totalPremComparison =
		props.valuation.annual_total_premium > 0
			? (
					(summedTotalAnnualPrem /
						props.valuation.annual_total_premium) *
					100
			  ).toFixed(2)
			: 0;

	const totalCommComparison =
		props.valuation.annual_total_commission > 0
			? (
					(summedAnnualAgencyComm /
						props.valuation.annual_total_commission) *
					100
			  ).toFixed(2)
			: 0;

	const yearTotal = (year: number) =>
		formatCurrency(
			data.lhCarriers.reduce(
				(accumulator, currentValue) =>
					accumulator + (currentValue?.overrides?.[year] || 0),
				0
			)
		);

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Top 5 Group / L&H Carriers'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='lhCarrierNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='carrier_name'>Carrier Name</th>
							<th id='total_annual_premium'>
								Total Annual Premium
							</th>
							<th id='percent_of_total_agency_premiums'>
								Percent Of Total Agency Premiums
							</th>
							<th id='annual_agency_commission'>
								Annual Agency Commission
							</th>
							<th id='percent_of_total_agency_commissions'>
								Percent Of Total Agency Commissions
							</th>
							<th id='delete_element'></th>
						</tr>
					</thead>
					<tbody>
						{data.lhCarriers.map((lhCarrier, index) => (
							<LhCarrierRow
								key={`lhCarrier-${index}`}
								lhCarrier={lhCarrier}
								annual_total_premium={
									props.valuation.annual_total_premium
								}
								annual_total_commission={
									props.valuation.annual_total_commission
								}
								index={index}
								errors={errors}
								updateIndex={updateIndex}
								deleteIndex={deleteIndex}
							/>
						))}
					</tbody>
					<tfoot>
						<tr>
							<th />
							<th />
							<th>{formatCurrency(summedTotalAnnualPrem)}</th>
							<th>{totalPremComparison}%</th>
							<th>{formatCurrency(summedAnnualAgencyComm)}</th>
							<th>{totalCommComparison}%</th>
							<th />
						</tr>
					</tfoot>
				</Table>

				<h1 className='h4'>Override $'s Received by Year</h1>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='lhCarrierOverrideNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='carrier_name_override'>Carrier Name</th>
							{range(
								props.currentYear - 5,
								props.currentYear
							).map((year) => (
								<th key={year}>{year}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.lhCarriers.map((lhCarrier, index) => (
							<LhCarrierOverridesRow
								key={`lhCarrier-overrides-${index}`}
								currentYear={props.currentYear}
								lhCarrier={lhCarrier}
								index={index}
								errors={errors}
								updateOverride={updateOverride}
							/>
						))}
					</tbody>
					<tfoot>
						<tr>
							<th />
							<th />
							{range(
								props.currentYear - 5,
								props.currentYear
							).map((year) => (
								<th key={year}>{yearTotal(year)}</th>
							))}
						</tr>
					</tfoot>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button
						onClick={addRow}
						disabled={
							data.lhCarriers.length >=
							props.maxLhCarriersPerValuation
						}
						className='mt-4'
					>
						Add Row
					</Button>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

LhCarriers.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default LhCarriers;
