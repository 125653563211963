import React, { ReactNode, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import PlSubcategory from '../../../Data/PlSubcategory';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import { FormData } from './CategoryForm';
import { getEventValue } from '../Helpers/RepeatingForms';
import formatCurrency from '../Helpers/FormatCurrency';
import calculatePercentage from '../Helpers/CalculatePercentage';

interface Props {
	categoryIndex?: number;
	subcategory: PlSubcategory;
	subcategoryIndex: number;
	errors: Errors & ErrorBag;
	data: FormData;
	setData: any;
	totalAdjustedNetRevenues: number;
}

const ProFormaTable = ({
	categoryIndex,
	subcategory,
	subcategoryIndex,
	errors,
	data,
	setData,
	totalAdjustedNetRevenues,
}: Props) => {
	const arrayName = `subcategories.${subcategory.id}.pl_financial`;
	const errorArrayName = `subcategories.${subcategory.id}.pl_pro_formas`;

	useEffect(() => {
		if (subcategory.pl_pro_formas.length == 0) {
			addRow();
		}
	}, [data]);

	const addRow = () => {
		const newData: any = { ...data };
		const subcategory = categoryIndex != null 
			? newData.categories[categoryIndex].subcategories[subcategoryIndex]
			: newData.subcategories[subcategoryIndex];

		subcategory.pl_pro_formas = [
			...subcategory.pl_pro_formas,
			{
				id: '',
				pl_subcategory_id: subcategory.id,
				value: '',
				note: '',
			},
		];

		setData(newData);
	};

	const deleteRow = (proFormaIndex: number) => {
		const newData: any = { ...data };
		categoryIndex != null
			? newData.categories[categoryIndex].subcategories[
					subcategoryIndex
			  ].pl_pro_formas.splice(proFormaIndex, 1)
			: newData.subcategories[subcategoryIndex].pl_pro_formas.splice(
				proFormaIndex,
					1
			  );
		setData(newData);
	};

	const updateRowIndex = (
		proFormaIndex: number,
		event: React.ChangeEvent<any>
	) => {
		const newData: any = { ...data };
		const subcategory = categoryIndex != null 
			? newData.categories[categoryIndex].subcategories[subcategoryIndex]
			: newData.subcategories[subcategoryIndex];
		subcategory.pl_pro_formas[proFormaIndex][event.target.name] =
			getEventValue(event);
		setData(newData);
	};

	const currentTotal = subcategory.pl_financials.reduce(
		(accumulator, pl_financial) =>
			accumulator + (pl_financial.value_current || 0),
		0
	);

	const currentAdjustments = subcategory.pl_pro_formas.reduce(
		(accumulator, pl_pro_forma) => accumulator + (pl_pro_forma.value || 0),
		0
	);
	const currentProForma = currentTotal + currentAdjustments; //wrong?

	return (
		<div>
			<h4 className='h5'>Pro Forma Adjustments</h4>
			<Table striped bordered hover responsive>
				<thead>
					<tr>
						<th
							id={`${subcategory.name}Num`}
							className='cell-xs sticky-cell'
						>
							#
						</th>
						<th>Note</th>
						<th>Pro Forma Adjustments</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{subcategory.pl_pro_formas.map(
						(proForma, proFormaIndex) => (
							<tr
								key={`sub-${subcategoryIndex}-proForma-${proFormaIndex}`}
							>
								<td className='row-num'>{proFormaIndex + 1}</td>
								<td>
									<TextInput
										name='note'
										index={proFormaIndex}
										value={proForma.note}
										onChange={(event) =>
											updateRowIndex(proFormaIndex, event)
										}
										errors={errors}
										arrayName={arrayName}
										errorArrayName={errorArrayName}
									/>
								</td>
								<td>
									<DecimalInput
										name='value'
										index={proFormaIndex}
										value={proForma.value}
										onChange={(event) =>
											updateRowIndex(proFormaIndex, event)
										}
										errors={errors}
										arrayName={arrayName}
										errorArrayName={errorArrayName}
										negativeValues={true}
									/>
								</td>
								<td className='d-flex justify-content-end'>
									<Button
										variant='outline-primary'
										onClick={(event) =>
											deleteRow(proFormaIndex)
										}
									>
										Delete
									</Button>
								</td>
							</tr>
						)
					)}
				</tbody>
				<tfoot>
					<tr>
						<th />
						<th>Income Statement Adjustments</th>
						<th>{formatCurrency(currentAdjustments)}</th>
						<th />
					</tr>
					<tr>
						<th />
						<th>Pro Forma</th>
						<th>{formatCurrency(currentProForma)}</th>
						<th />
					</tr>
					<tr>
						<th />
						<th>% of Net Revenues</th>
						<th>
							{calculatePercentage(
								currentProForma,
								totalAdjustedNetRevenues
							)}
						</th>
						<th />
					</tr>
				</tfoot>
			</Table>

			<div className='d-flex justify-content-end'>
				<Button onClick={(event) => addRow()} className='mt-4'>
					Add Row
				</Button>
			</div>
		</div>
	);
};

export default ProFormaTable;
