import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import cn from 'classnames';
import rowHasErrors from '../Helpers/RowHasErrors';
import Owner from '../../../Data/Owner';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';

interface Props {
	owner: Partial<Owner>;
	totalShares: number;
	index: number;
	errors: Errors & ErrorBag;
	updateRow: (event: any, index: number) => void;
	deleteRow: (index: number) => void;
}

const OwnerRow = ({
	owner,
	totalShares,
	index,
	errors,
	updateRow,
	deleteRow,
}: Props) => {
	const arrayName = 'owner';
	const errorArrayName = 'owner';
	const equity = Math.round(
		(100 * (owner.number_of_shares ?? 0)) /
			(totalShares > 0 ? totalShares : 1)
	);
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`'owner-${index + 1}`} className='row-num sticky-cell'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='first_name'
					index={index}
					value={owner.first_name}
					onChange={(event) => updateRow(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='last_name'
					index={index}
					value={owner.last_name}
					onChange={(event) => updateRow(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='age'
					index={index}
					value={owner.age}
					onChange={(event) => updateRow(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					digits={0}
					prefix=''
				/>
			</td>
			<td>
				<DecimalInput
					name='number_of_shares'
					index={index}
					value={owner.number_of_shares}
					onChange={(event) => updateRow(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					digits={0}
					prefix=''
				/>
			</td>
			<td className='text-center align-middle'>{`${equity} %`}</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteRow(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default OwnerRow;
