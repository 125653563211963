import React, { ReactNode, useEffect } from 'react';
import { Row, Col, Button, Table } from 'react-bootstrap';

import Valuation from '../../Data/Valuation';
import ThreeYearBreakdown from '../../Data/ThreeYearBreakdown';
import PlCategory from '../../Data/PlCategory';
import AuthedLayout from '../../Layouts/Authed';
import FormHeader from '../../Components/Forms/FormHeader';
import OverallYearSummary from './PlSummary/OverallYearSummary';

interface Props {
	valuation: Valuation;
	currentYear: number;
	netRevenue: ThreeYearBreakdown;
	netISA: number;
	netProForma: number;
	totalExpenses: ThreeYearBreakdown;
	totalISA: number;
	totalProForma: number;
	preTaxProfit: ThreeYearBreakdown;
	preTaxISA: number;
	preTaxProForma: number;
	ebitda: ThreeYearBreakdown;
	ebitdaISA: number;
	ebitdaProForma: number;
}

const PlSummary = (props: Props) => {
	const one_year_ago = props.currentYear - 1;
	const two_years_ago = props.currentYear - 2;

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='P&L Summary'
			/>
			{/* table for each year */}
			{/* tables for year-1 and year-2 are small - two cols? */}
			<Row>
				<Col lg={6}>
					<h2 className='h3'>{two_years_ago}</h2>
					<OverallYearSummary
						netRevenue={props.netRevenue.value_two_years_ago}
						totalExpenses={props.totalExpenses.value_two_years_ago}
						preTaxProfit={props.preTaxProfit.value_two_years_ago}
						ebitda={props.ebitda.value_two_years_ago}
					/>
				</Col>
				<Col lg={6}>
					<h2 className='h3'>{one_year_ago}</h2>
					<OverallYearSummary
						netRevenue={props.netRevenue.value_one_year_ago}
						totalExpenses={props.totalExpenses.value_one_year_ago}
						preTaxProfit={props.preTaxProfit.value_one_year_ago}
						ebitda={props.ebitda.value_one_year_ago}
					/>
				</Col>
			</Row>

			<Row>
				<Col>
					<h2 className='h3'>{props.currentYear}</h2>
					<OverallYearSummary
						netRevenue={props.netRevenue.value_current}
						netISA={props.netISA}
						netProForma={props.netProForma}
						totalExpenses={props.totalExpenses.value_current}
						totalISA={props.totalISA}
						totalProForma={props.totalProForma}
						preTaxProfit={props.preTaxProfit.value_current}
						preTaxISA={props.preTaxISA}
						preTaxProForma={props.preTaxProForma}
						ebitda={props.ebitda.value_current}
						ebitdaISA={props.ebitdaISA}
						ebitdaProForma={props.ebitdaProForma}
						showProFormaColumns={true}
					/>
				</Col>
			</Row>
		</div>
	);
};

PlSummary.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default PlSummary;
