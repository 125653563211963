import React from 'react';
import { Link, usePage } from '@inertiajs/inertia-react';
import { Nav } from 'react-bootstrap';
import PlCategory from '../Data/PlCategory';

interface Props {
	valuationId: number;
	pageName: string;
	pageIndex?: string | number;
}

interface Page {
	name: string;
	label: string;
}

const SideNav = ({ valuationId, pageName, pageIndex }: Props) => {
	const { navPlCategories } = usePage<any>().props;

	const url = `/valuation-form/${valuationId}`;
	const pages: Page[] = [
		{
			name: 'basics',
			label: 'Basics',
		},
		{
			name: 'ownership',
			label: 'Ownership',
		},
		{
			name: 'employees',
			label: 'Employees',
		},
		{
			name: 'accounts',
			label: 'Top Ten Accounts',
		},
		{
			name: 'pc-carriers',
			label: 'Top 5 P&C Carriers',
		},
		{
			name: 'lh-carriers',
			label: 'Top 5 L&H Carriers',
		},
		{
			name: 'producer-bobs',
			label: 'Producer Books of Business',
		},
		{
			name: 'non-recurring-revenues',
			label: 'Non-recurring Revenues',
		},
		{
			name: 'non-recurring-expenses',
			label: 'Non-recurring Expenses',
		},
		{
			name: 'agency-information',
			label: 'Agency Information',
		},
		{
			name: 'carrier-loss-ratios',
			label: 'Loss Ratio By Carrier',
		},
		{
			name: 'carrier-retention-rates',
			label: 'Retention Rate By Carrier',
		},
	];

	return (
		<Nav className='side-nav my-8 flex-column'>
			{pages.map((page: Page) => (
				<Nav.Link
					as={Link}
					key={page.name}
					active={pageName === page.name}
					href={`${url}/${page.name}`}
				>
					{page.label}
				</Nav.Link>
			))}
			<hr className='border-dkBlueGrey' />

			<span className='h4 fw-bold text-dkBlueGrey'>Net Revenues</span>
			{navPlCategories?.netRevenues.map((category: PlCategory) => (
				<Nav.Link
					as={Link}
					key={category.id}
					active={pageIndex == category.id}
					href={`${url}/pl-category/${category.id}`}
				>
					{category.name}
				</Nav.Link>
			))}
			<Nav.Link
				as={Link}
				active={pageName === 'net-revenue-summary'}
				href={`${url}/net-revenue-summary`}
			>
				Summary
			</Nav.Link>

			<hr className='border-dkBlueGrey' />
			<Nav.Link
				as={Link}
				className='h4 p-0 fw-bold'
				style={{fontSize: '1rem'}}
				active={pageName == 'total-expenses'}
				href={`${url}/total-expenses`}
			>
				Total Expenses
			</Nav.Link>
			{navPlCategories?.totalExpenses.map((category: PlCategory) => (
				<Nav.Link
					as={Link}
					key={category.id}
					active={pageIndex == category.id}
					href={`${url}/pl-category/${category.id}`}
				>
					{category.name}
				</Nav.Link>
			))}
			<Nav.Link
				as={Link}
				active={pageName === 'total-expenses-summary'}
				href={`${url}/total-expenses-summary`}
			>
				Summary
			</Nav.Link>

			<hr className='border-dkBlueGrey' />
			<span className='h4 fw-bold text-dkBlueGrey'>
				Additional Information
			</span>
			{navPlCategories?.additionalInformation.map(
				(category: PlCategory) => (
					<Nav.Link
						as={Link}
						key={category.id}
						active={pageIndex == category.id}
						href={`${url}/pl-category/${category.id}`}
					>
						{category.name}
					</Nav.Link>
				)
			)}

			<hr className='border-dkBlueGrey' />
			<Nav.Link
				as={Link}
				active={pageName === 'pl-summary'}
				href={`${url}/pl-summary`}
			>
				P&L Summary
			</Nav.Link>
		</Nav>
	);
};

export default SideNav;
