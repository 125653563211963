import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';

import formatDigits from '../../Pages/Valuation/Helpers/FormatDigits';
import onlyPositive from '../../Pages/Valuation/Helpers/OnlyPositive';

interface Props {
	name: string;
	label?: string;
	index?: number;
	value: number | string | undefined | '';
	onChange: (event: React.ChangeEvent<any>, index?: number) => void;
	errors: Errors & ErrorBag;
	arrayName: string;
	errorArrayName: string; // name of pluralized model relationship on valuation
	prefix?: string | null;
	postfix?: string | null;
	negativeValues?: boolean;
	digits?: number;
	overrideArrayName?: boolean | null; // when default array name isn't accurate due to different nesting
	overrideErrorArrayName?: boolean | null;
}

/**
 * Custom input for text form controls nested inside repeating rows
 * @returns
 */
const DecimalInput = ({
	name,
	index,
	errors,
	onChange,
	arrayName,
	errorArrayName,
	value,
	prefix = '$',
	postfix = null,
	negativeValues = false,
	digits,
	overrideArrayName = false,
	overrideErrorArrayName = false,
	label,
}: Props) => {
	const errorString = overrideErrorArrayName
		? errorArrayName
		: `${errorArrayName}.${index}.${name}`;
	const ariaLabel = !overrideArrayName
		? arrayName
		: `${arrayName}-${(index ?? 0) + 1} ${name}`;
	return (
		<Form.Group>
			{label && (
				<Form.Label id={ariaLabel}>
					{label}
				</Form.Label>
			)}
			<InputGroup>
				{prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
				<Form.Control
					name={name}
					type='number'
					aria-labelledby={ariaLabel}
					value={value}
					onChange={(event) => onChange(event, index)}
					onBlur={(event) => {
						formatDigits(event, digits);
						if (!negativeValues) {
							onlyPositive(event);
						}
						onChange(event, index);
					}}
					isInvalid={!!errors[errorString]}
				/>
				{postfix && <InputGroup.Text>{postfix}</InputGroup.Text>}
			</InputGroup>
			{errors[errorString] && (
				<Form.Control.Feedback type='invalid'>
					{errors[errorString]}
				</Form.Control.Feedback>
			)}
		</Form.Group>
	);
};

export default DecimalInput;
