import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

import NonRecurringExpense from '../../../Data/NonRecurringExpense';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import rowHasErrors from '../Helpers/RowHasErrors';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';

interface Props {
	non_recurring_expense: Partial<NonRecurringExpense>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: any, index: number) => void;
	deleteIndex: (index: number) => void;
}

const NonRecurringExpenseRow = ({
	non_recurring_expense,
	index,
	errors,
	updateIndex,
	deleteIndex,
}: Props) => {
	const arrayName = 'non_recurring_expense';
	const errorArrayName = 'non_recurring_expenses';
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`non_recurring_expense-${index + 1}`} className='row-num'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='expense'
					index={index}
					value={non_recurring_expense.expense}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='p_l_line_item'
					index={index}
					value={non_recurring_expense.p_l_line_item}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='total_expense_dollars_booked'
					index={index}
					value={non_recurring_expense.total_expense_dollars_booked}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='num_years_with_agency'
					index={index}
					value={non_recurring_expense.num_years_with_agency}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					prefix={null}
					negativeValues={false}
					digits={0}
				/>
			</td>
			
			<td>
				<TextInput
					name='description_reason'
					index={index}
					value={non_recurring_expense.description_reason}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default NonRecurringExpenseRow;
