import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table, Alert } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import Valuation from '../../Data/Valuation';
import NonRecurringRevenue from '../../Data/NonRecurringRevenue';
import NonRecurringRevenueRow from './NonRecurringRevenue/NonRecurringRevenueRow';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import FormHeader from '../../Components/Forms/FormHeader';
import formatCurrency from './Helpers/FormatCurrency';

interface Props {
	valuation: Valuation;
	non_recurring_revenue: NonRecurringRevenue[];
}

interface FormData {
	non_recurring_revenue: Partial<NonRecurringRevenue>[];
}

const NonRecurringRevenues = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post } = useForm<FormData>({
		non_recurring_revenue:
			replaceNullWithEmpty(props.non_recurring_revenue) ?? [],
	});

	useEffect(() => {
		if (data.non_recurring_revenue.length == 0) {
			addRow();
		}
	}, [props.non_recurring_revenue]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/non-recurring-revenues`);
	};

	const addRow = () => {
		setData({
			...data,
			non_recurring_revenue: [
				...data.non_recurring_revenue,
				{
					id: '',
					account_name: '',
					industry: '',
					total_agency_commissions_booked: '',
					num_years_with_agency: '',
					agency_producer: '',
				},
			] as NonRecurringRevenue[],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData: any = { ...data };
		newData.non_recurring_revenue[index][event.target.name] =
			getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData: any = { ...data };
		newData.non_recurring_revenue.splice(index, 1);
		setData(newData);
	};

	const totalCommissionsLost = formatCurrency(
		data.non_recurring_revenue.reduce(
			(accumulator, currentValue) =>
				accumulator + (currentValue?.total_agency_commissions_booked || 0),
			0
		)
	);

	const avgYearsWithAgency = data.non_recurring_revenue.reduce(
		(accumulator, currentValue) =>
			accumulator + (currentValue?.num_years_with_agency || 0),
		0
	) / data.non_recurring_revenue.length;

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Non-recurring Revenues'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='non_recurring_revenueNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='account_name'>Account Name</th>
							<th id='industry'>Industry</th>
							<th
								id='total_agency_commissions_booked'
								className='cell-lg'
							>
								Total Agency Commissions Booked for TTM
							</th>
							<th id='num_years_with_agency'>
								# Years with Agency
							</th>
							<th id='agency_producer'>Agency Producer</th>
							<th id='delete_element' className='cell-sm'></th>
						</tr>
					</thead>
					<tbody>
						{data.non_recurring_revenue.map(
							(
								non_recurring_revenue: Partial<NonRecurringRevenue>,
								index: number
							) => (
								<NonRecurringRevenueRow
									key={`non_recurring_revenue-${index}`}
									non_recurring_revenue={
										non_recurring_revenue
									}
									index={index}
									errors={errors}
									updateIndex={updateIndex}
									deleteIndex={deleteIndex}
								/>
							)
						)}
					</tbody>
					<tfoot>
						<tr>
							<th />
							<th />
							<th />
							<th>{totalCommissionsLost}</th>
							<th>Avg: {avgYearsWithAgency}</th>
							<th />
							<th />
						</tr>
					</tfoot>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button onClick={addRow} className='mt-4'>
						Add Row
					</Button>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

NonRecurringRevenues.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default NonRecurringRevenues;
