import React, { ReactNode } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Inertia } from '@inertiajs/inertia';
import SharedProps from '../Data/SharedProps';
import AuthedLayout from '../Layouts/Authed';

const Home = (props: SharedProps) => {
	return (
		<div className="admin-dashboard">
			<Row>
				<Col>
					<h2>Welcome!</h2>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col>
					<Button
						variant="primary"
						onClick={() => Inertia.get('/valuation-form/basics')}
					>Begin New Valuation</Button>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col>
					{props.user ? (
						<Button onClick={() => Inertia.post('/logout')}>Log Out</Button>
					) : (
						'You are not logged in'
					)}
				</Col>
			</Row>
		</div>
	);
};

Home.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default Home;
