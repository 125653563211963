import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

import ThreeYearBreakdown from '../../../Data/ThreeYearBreakdown';

import calculatePercentage from '../Helpers/CalculatePercentage';
import formatCurrency from '../Helpers/FormatCurrency';

interface Props {
	categoryISA: number;
	categoryNetRevenues: ThreeYearBreakdown;
	categoryProForma: number;
	currentYear: number;
	netProForma: number;
	totalNetRevenues: ThreeYearBreakdown;
}

const CategorySummaryTable = ({
	categoryISA,
	categoryNetRevenues,
	categoryProForma,
	currentYear,
	netProForma,
	totalNetRevenues,
}: Props) => {
	return (
		<Row>
			<h3 className='h4 mt-6'>Summary</h3>
			<Col lg={6}>
				<Table
					striped
					bordered
					hover
					responsive
					className='summaryTable'
				>
					<thead>
						<tr>
							<th>Year</th>
							<th>Actual</th>
							<th>% of Net Revenues</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{currentYear - 2}</td>
							<td>
								{formatCurrency(
									categoryNetRevenues.value_two_years_ago
								)}
							</td>
							<td>
								{calculatePercentage(
									categoryNetRevenues.value_two_years_ago,
									totalNetRevenues.value_two_years_ago
								)}
							</td>
						</tr>
						<tr>
							<td>{currentYear - 1}</td>
							<td>
								{formatCurrency(
									categoryNetRevenues.value_one_year_ago
								)}
							</td>
							<td>
								{calculatePercentage(
									categoryNetRevenues.value_one_year_ago,
									totalNetRevenues.value_one_year_ago
								)}
							</td>
						</tr>
						<tr>
							<td>{currentYear}</td>
							<td>
								{formatCurrency(
									categoryNetRevenues.value_current
								)}
							</td>
							<td>
								{calculatePercentage(
									categoryNetRevenues.value_current,
									totalNetRevenues.value_current
								)}
							</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col lg={6}>
				<Table
					striped
					bordered
					hover
					responsive
					className='summaryTable'
				>
					<thead>
						<tr>
							<th>Income Statement Adjustments</th>
							<th>Pro Forma</th>
							<th>% of Net Revenues</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{formatCurrency(categoryISA)}</td>
							<td>{formatCurrency(categoryProForma)}</td>
							<td>
								{calculatePercentage(
									categoryProForma,
									netProForma
								)}
							</td>
						</tr>
					</tbody>
				</Table>
			</Col>
		</Row>
	);
};

export default CategorySummaryTable;
