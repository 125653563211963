import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import rowHasErrors from '../Helpers/RowHasErrors';
import CarrierRetentionRate from '../../../Data/CarrierRetentionRate';

interface Props {
	currentYear: number;
	retentionRate: Partial<CarrierRetentionRate>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: React.ChangeEvent<any>, index: number) => void;
	deleteIndex: (index: number) => void;
	updateYear: (
		index: number,
		yearIndex: number,
		event: React.ChangeEvent<any>
	) => void;
}

const CarrierRetentionRateRow = ({
	currentYear,
	retentionRate,
	index,
	errors,
	updateIndex,
	deleteIndex,
	updateYear,
}: Props) => {
	const arrayName = 'carrier_retention_rate';
	const errorArrayName = 'carrier_retention_rates';
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td
				id={`${arrayName}-${index + 1}`}
				className='row-num sticky-cell'
			>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='carrier_name'
					index={index}
					value={retentionRate.carrier_name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			{retentionRate.years?.map((year, yearIndex) => (
				<td key={`${index}-${year.year}`}>
					<DecimalInput
						name='retention_rate'
						index={index}
						prefix={null}
						postfix='%'
						value={year.retention_rate ?? ''}
						onChange={(event) =>
							updateYear(index, yearIndex, event)
						}
						errors={errors}
						arrayName={`${arrayName}-${index + 1} ${
							year.year
						}-retention_rate`}
						errorArrayName={`${errorArrayName}.${index}.years.${yearIndex}.retention_rate`}
						overrideArrayName={true}
						overrideErrorArrayName={true}
					/>
				</td>
			))}
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default CarrierRetentionRateRow;
