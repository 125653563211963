import React from 'react';
import { Form } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';

interface Props {
	name: string;
	index: number;
	value: string | undefined;
	onChange: (event: React.ChangeEvent<any>, index: number) => void;
	errors: Errors & ErrorBag;
	arrayName: string;
	errorArrayName: string; // name of pluralized model relationship on valuation
	disabled?: boolean;
}

/**
 * Custom input for text form controls nested inside repeating rows
 * @returns
 */
const TextInput = ({
	name,
	index,
	errors,
	onChange,
	arrayName,
	errorArrayName,
	value,
	disabled,
}: Props) => {
	const errorString = `${errorArrayName}.${index}.${name}`;
	const ariaLabel = `${arrayName}-${index + 1} ${name}`;
	return (
		<Form.Group>
			<Form.Control
				name={name}
				aria-labelledby={ariaLabel}
				value={value}
				onChange={(event) => onChange(event, index)}
				isInvalid={!!errors[errorString]}
				disabled={disabled}
			/>
			{errors[errorString] && (
				<Form.Control.Feedback type='invalid'>
					{errors[errorString]}
				</Form.Control.Feedback>
			)}
		</Form.Group>
	);
};

export default TextInput;
