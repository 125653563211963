import React, { ReactNode } from 'react';
import { Link, useForm } from '@inertiajs/inertia-react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import GuestLayout from '../../Layouts/Guest';

interface Props {
	status?: string;
}

const Login = (props: Props) => {
	const { data, setData, post, processing, errors, reset } = useForm({
		email: '',
		password: '',
		remember: false,
	});

	const submit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		post('/login');
	};

	return (
		<div className='vh-100 d-flex flex-column justify-content-center align-items-center'>
			<Card className='login-card p-3'>
				<Card.Body>
					{props.status && <Alert className='mb-3'>{props.status}</Alert>}
					<h1 className='fs-4 fw-bold mb-4'>
						Welcome to IA Valuations
					</h1>
					<Form onSubmit={submit}>
						<Form.Group controlId='email' className='mb-3'>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type='email'
								value={data.email}
								onChange={(e) =>
									setData('email', e.target.value)
								}
							/>
							<div className='text-dkRed'>{errors.email}</div>
						</Form.Group>
						<Form.Group controlId='password' className='mb-3'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type='password'
								value={data.password}
								onChange={(e) =>
									setData('password', e.target.value)
								}
							/>
							<div className='mt-2 text-dkRed'>{errors.password}</div>
						</Form.Group>
						<Link href='/forgot-password'>Forgot password?</Link>
						<Button
							variant='primary'
							type='submit'
							disabled={processing}
							className='mt-3 w-100'
						>
							Sign In
						</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
};

Login.layout = (page: ReactNode) => <GuestLayout>{page}</GuestLayout>;

export default Login;
