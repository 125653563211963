import React, { ReactNode, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from '@inertiajs/inertia-react';

import Valuation from '../../Data/Valuation';
import ThreeYearBreakdown from '../../Data/ThreeYearBreakdown';
import PlCategory from '../../Data/PlCategory';
import AuthedLayout from '../../Layouts/Authed';
import FormHeader from '../../Components/Forms/FormHeader';
import YearSummary from './NetRevenueSummary/YearSummary';

interface Props {
	valuation: Valuation;
	currentYear: number;
	categories: PlCategory[];
	grossRevenue: ThreeYearBreakdown;
	grossISA: number;
	grossWithProForma: number;
	netRevenue: ThreeYearBreakdown;
	netISA: number;
	netRevenueWithProForma: number;
	nextPage: string;
}

const NetRevenueSummary = (props: Props) => {
	const one_year_ago = props.currentYear - 1;
	const two_years_ago = props.currentYear - 2;

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Net Revenue Summary'
			/>
			{/* table for each year */}
			{/* tables for year-1 and year-2 are small - two cols? */}
			<Row>
				<Col lg={6}>
					<h2 className='h3'>{two_years_ago}</h2>
					<YearSummary
						name={'value_two_years_ago'}
						categories={props.categories}
						grossRevenue={props.grossRevenue.value_two_years_ago}
						netRevenue={props.netRevenue.value_two_years_ago}
					/>
				</Col>
				<Col lg={6}>
					<h2 className='h3'>{one_year_ago}</h2>
					<YearSummary
						name={'value_one_year_ago'}
						categories={props.categories}
						grossRevenue={props.grossRevenue.value_one_year_ago}
						netRevenue={props.netRevenue.value_one_year_ago}
					/>
				</Col>
			</Row>

			<h2 className='h3'>{props.currentYear}</h2>
			<Row>
				<Col>
					<YearSummary
						name={'value_current'}
						categories={props.categories}
						grossRevenue={props.grossRevenue.value_current}
						grossISA={props.grossISA}
						grossWithProForma={props.grossWithProForma}
						netRevenue={props.netRevenue.value_current}
						netRevenueWithProForma={props.netRevenueWithProForma}
						netISA={props.netISA}
						showProFormaColumns={true}
					/>
				</Col>
			</Row>

			<div className='d-flex justify-content-end'>
				<Link href={props.nextPage} className='mt-4 btn btn-primary'>
					Next
				</Link>
			</div>
		</div>
	);
};

NetRevenueSummary.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default NetRevenueSummary;
