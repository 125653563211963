import React, { ReactNode } from 'react';
import { Row, Col, Table } from 'react-bootstrap';

import Valuation from '../../../Data/Valuation';
import ThreeYearBreakdown from '../../../Data/ThreeYearBreakdown';
import PlCategory from '../../../Data/PlCategory';
import AuthedLayout from '../../../Layouts/Authed';
import FormHeader from '../../../Components/Forms/FormHeader';
import formatCurrency from '../Helpers/FormatCurrency';
import calculatePercentage from '../Helpers/CalculatePercentage';

interface Props {
	name: string;
	categories: PlCategory[];
	grossRevenue?: number;
	grossISA?: number;
	grossWithProForma?: number;
	netRevenue: number;
	netISA?: number;
	netRevenueWithProForma?: number;
	showProFormaColumns?: boolean;
	showNetRevenues?: boolean;
	totalExpenses?: number;
	totalISA?: number;
	totalExpensesWithProForma?: number;
}

const YearSummary = ({
	name,
	categories,
	grossRevenue,
	grossISA,
	grossWithProForma,
	netRevenue,
	netISA,
	netRevenueWithProForma,
	showProFormaColumns = false,
	showNetRevenues = true,
	totalExpenses,
	totalISA,
	totalExpensesWithProForma,
}: Props) => {
	return (
		<Table striped bordered hover responsive>
			<thead>
				<tr>
					<th>Category</th>
					<th>Actual</th>
					<th>% of Net Revenues</th>
					{showProFormaColumns && (
						<>
							<th>Income Statement Adjustments</th>
							<th>Pro Forma</th>
							<th>% of Net Revenues</th>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				{categories.map((category: PlCategory) => (
					<tr key={category.name}>
						<td>{category.name}</td>
						<td>
							{formatCurrency(
								category.calculated_actual?.[name] ?? 0,
								category.subtracted_from_sums
							)}
						</td>
						<td>
							{calculatePercentage(
								category.calculated_actual?.[name],
								netRevenue
							)}
						</td>
						{showProFormaColumns && (
							<>
								<td>
									{formatCurrency(
										category.income_statement_adjustments ??
											0,
										category.subtracted_from_sums
									)}
								</td>
								<td>
									{formatCurrency(
										category.calculated_pro_forma ?? 0,
										category.subtracted_from_sums
									)}
								</td>
								<td>
									{calculatePercentage(
										category.calculated_pro_forma ?? 0,
										netRevenueWithProForma ?? 1
									)}
								</td>
							</>
						)}
					</tr>
				))}
			</tbody>
			<tfoot className='fw-bold'>
				{grossRevenue !== undefined && (
					<tr>
						<td>Gross Revenue</td>
						<td>{formatCurrency(grossRevenue ?? 0)}</td>
						<td>
							{calculatePercentage(
								grossRevenue ?? 0,
								netRevenue
							)}
						</td>
						{showProFormaColumns && (
							<>
								<td>{formatCurrency(grossISA ?? 0)}</td>
								<td>
									{formatCurrency(grossWithProForma ?? 0)}
								</td>
								<td>
									{calculatePercentage(
										grossWithProForma ?? 0,
										netRevenueWithProForma ?? 1
									)}
								</td>
							</>
						)}
					</tr>
				)}
				{/* if no total expenses, show net revenue */}
				{totalExpenses === undefined && (
					<tr>
						<td>Net Revenue</td>
						<td>{formatCurrency(netRevenue)}</td>
						<td>
							{calculatePercentage(
								netRevenue,
								netRevenue
							)}
						</td>
						{showProFormaColumns && (
							<>
								<td>{formatCurrency(netISA ?? 0)}</td>
								<td>
									{formatCurrency(
										netRevenueWithProForma ?? 0
									)}
								</td>
								<td>
									{calculatePercentage(
										netRevenueWithProForma ?? 0,
										netRevenueWithProForma ?? 1
									)}
								</td>
							</>
						)}
					</tr>
				)}
				{totalExpenses !== undefined && (
					<tr>
						<td>Total Expenses</td>
						<td>{formatCurrency(totalExpenses)}</td>
						<td>
							{calculatePercentage(
								totalExpenses,
								netRevenue
							)}
						</td>
						{showProFormaColumns && (
							<>
								<td>{formatCurrency(totalISA ?? 0)}</td>
								<td>
									{formatCurrency(
										totalExpensesWithProForma ?? 0
									)}
								</td>
								<td>
									{calculatePercentage(
										totalExpensesWithProForma ?? 0,
										netRevenueWithProForma ?? 1
									)}
								</td>
							</>
						)}
					</tr>
				)}
			</tfoot>
		</Table>
	);
};

YearSummary.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default YearSummary;
