import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table, Row, Col } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import Valuation from '../../Data/Valuation';
import CarrierRetentionRate from '../../Data/CarrierRetentionRate';
import CarrierRetentionRateYear from '../../Data/CarrierRetentionRateYear';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import { range } from '../../utils';
import FormHeader from '../../Components/Forms/FormHeader';
import CarrierRetentionRateRow from './CarrierRetentionRates/CarrierRetentionRateRow';
import DecimalInput from '../../Components/Forms/DecimalInput';

interface Props {
	valuation: Valuation;
	carrier_retention_rates: CarrierRetentionRate[];
	maxCarrierRetentionRatesPerValuation: number;
	currentYear: number;
}

interface FormData {
	total_agency_retention_rate: number;
	carrier_retention_rates: Partial<CarrierRetentionRate>[];
}

const CarrierRetentionRates = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		total_agency_retention_rate: props.valuation.total_agency_retention_rate ?? '',
		carrier_retention_rates:
			replaceNullWithEmpty(props.carrier_retention_rates) ?? [],
	});

	useEffect(() => {
		if (data.carrier_retention_rates.length == 0) {
			addRow();
		}
	}, [props.carrier_retention_rates]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/carrier-retention-rates`);
	};

	const addRow = () => {
		setData({
			...data,
			carrier_retention_rates: [
				...data.carrier_retention_rates,
				{
					carrier_name: '',
					years: range(
						props.currentYear - 5,
						props.currentYear
					).map((year) => {
						return {
							year: year,
							retention_rate: '',
						};
					}),
				},
			] as CarrierRetentionRate[],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.carrier_retention_rates[index][event.target.name] =
			getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.carrier_retention_rates.splice(index, 1);
		setData(newData);
	};

	const updateYear = (
		index: number,
		yearIndex: number,
		event: React.ChangeEvent<any>
	) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.carrier_retention_rates[index].years[yearIndex][event.target.name] =
			getEventValue(event);
		setData(newData);
	};

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Retention Rate By Carrier - Top 5 Carriers'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Row className='py-4'>
					<Col md={4}>
						<DecimalInput
							name='total_agency_retention_rate'
							label='What is your total agency retention rate?'
							prefix={null}
							postfix='%'
							value={data.total_agency_retention_rate ?? ''}
							onChange={(event) =>
								setData({
									...data,
									total_agency_retention_rate: getEventValue(event),
								})
							}
							errors={errors}
							arrayName='valuation.total_agency_retention_rate'
							errorArrayName='valuation.total_agency_retention_rate'
							overrideArrayName={true}
							overrideErrorArrayName={true}
						/>
					</Col>
				</Row>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='CarrierRetentionRateNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='carrier_name' className='cell-lg'>
								Carrier Name
							</th>
							{range(
								props.currentYear - 5,
								props.currentYear
							).map((year) => (
								<th id={`${year}-retention_rate`} key={year}>
									{year}
								</th>
							))}
							<th id='delete_element' className='cell-sm'></th>
						</tr>
					</thead>
					<tbody>
						{data.carrier_retention_rates.map((retentionRate, index) => (
							<CarrierRetentionRateRow
								key={`CarrierRetentionRate-${index}`}
								currentYear={props.currentYear}
								retentionRate={retentionRate}
								index={index}
								errors={errors}
								updateIndex={updateIndex}
								deleteIndex={deleteIndex}
								updateYear={updateYear}
							/>
						))}
					</tbody>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button
						onClick={addRow}
						disabled={
							data.carrier_retention_rates.length >=
							props.maxCarrierRetentionRatesPerValuation
						}
						className='mt-4'
					>
						Add Row
					</Button>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

CarrierRetentionRates.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default CarrierRetentionRates;
