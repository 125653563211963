import React, { ReactNode } from 'react';
import { Link, useForm } from '@inertiajs/inertia-react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import GuestLayout from '../../Layouts/Guest';

interface Props {
	email: string;
	token: string;
	status?: string;
}

const ResetPassword = (props: Props) => {
	const { data, setData, post, processing, errors, reset } = useForm({
		email: props.email,
		token: props.token,
		password: '',
		password_confirmation: '',
	});

	const submit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		post('/reset-password');
	};

	return (
		<div className='vh-100 d-flex flex-column justify-content-center align-items-center'>
			<Card className='login-card p-3'>
				<Card.Body>
					{props.status && <Alert className='mb-3'>{props.status}</Alert>}
					<h1 className='fs-4 fw-bold mb-4'>Reset Password</h1>
					<Form onSubmit={submit}>
						<input type='hidden' name='email' value={data.email} />
						<input type='hidden' name='token' value={data.token} />
						<Form.Group controlId='password' className='mb-3'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type='password'
								value={data.password}
								onChange={(e) =>
									setData('password', e.target.value)
								}
							/>
							<div className='text-dkRed'>{errors.password}</div>
						</Form.Group>
						<Form.Group
							controlId='password_confirmation'
							className='mb-3'
						>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type='password'
								value={data.password_confirmation}
								onChange={(e) =>
									setData(
										'password_confirmation',
										e.target.value
									)
								}
							/>
							<div className='mt-2 text-dkRed'>
								{errors.password_confirmation}
							</div>
						</Form.Group>
						<Button
							variant='primary'
							type='submit'
							disabled={processing}
							className='mt-3 w-100'
						>
							Reset Password
						</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
};

ResetPassword.layout = (page: ReactNode) => <GuestLayout>{page}</GuestLayout>;

export default ResetPassword;
