import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

import ProducerBookOfBusiness from '../../../Data/ProducerBookOfBusiness';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import rowHasErrors from '../Helpers/RowHasErrors';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';

interface Props {
	producer_bob: Partial<ProducerBookOfBusiness>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: any, index: number) => void;
	deleteIndex: (index: number) => void;
}

const ProducerBobRow = ({
	producer_bob,
	index,
	errors,
	updateIndex,
	deleteIndex,
}: Props) => {
	const arrayName = 'producer_bob';
	const errorArrayName = 'producer_bobs';
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`producer_bob-${index + 1}`} className='row-num'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='name'
					index={index}
					value={producer_bob.name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='employee_benefits_total_book'
					index={index}
					value={producer_bob.employee_benefits_total_book}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='p_c_total_book'
					index={index}
					value={producer_bob.p_c_total_book}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='producer_age'
					index={index}
					value={producer_bob.producer_age}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					prefix={null}
					negativeValues={false}
					digits={0}
				/>
			</td>
			{/* compensation_structure_splits */}
			<td>
				<TextInput
					name='compensation_structure_splits'
					index={index}
					value={producer_bob.compensation_structure_splits}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default ProducerBobRow;
