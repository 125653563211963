import React, { useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import PlSubcategory from '../../../Data/PlSubcategory';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import { FormData } from './CategoryForm';
import { getEventValue } from '../Helpers/RepeatingForms';
import formatCurrency from '../Helpers/FormatCurrency';
import calculatePercentage from '../Helpers/CalculatePercentage';
import ThreeYearBreakdown from '../../../Data/ThreeYearBreakdown';

interface Props {
	categoryIndex?: number;
	subcategory: PlSubcategory;
	subcategoryIndex: number;
	errors: Errors & ErrorBag;
	currentYear: number;
	data: FormData;
	setData: any;
	totalNetRevenues: ThreeYearBreakdown;
}

const SubcategoryTable = ({
	categoryIndex,
	subcategory,
	subcategoryIndex,
	errors,
	currentYear,
	data,
	setData,
	totalNetRevenues,
}: Props) => {
	const arrayName = `subcategories.${subcategory.id}.pl_financial`;
	const errorArrayName = `subcategories.${subcategory.id}.pl_financials`;

	useEffect(() => {
		if (subcategory.pl_financials.length == 0) {
			addRow();
		}
	}, [data]);

	const addRow = () => {
		const newData: any = { ...data };
		const subcategory =
			categoryIndex != null
				? newData.categories[categoryIndex].subcategories[
						subcategoryIndex
				  ]
				: newData.subcategories[subcategoryIndex];

		subcategory.pl_financials = [
			...subcategory.pl_financials,
			{
				id: '',
				pl_subcategory_id: subcategory.id,
				value_current: '',
				value_one_year_ago: '',
				value_two_years_ago: '',
				note: '',
			},
		];

		setData(newData);
	};

	const deleteRow = (financialIndex: number) => {
		const newData: any = { ...data };
		categoryIndex != null
			? newData.categories[categoryIndex].subcategories[
					subcategoryIndex
			  ].pl_financials.splice(financialIndex, 1)
			: newData.subcategories[subcategoryIndex].pl_financials.splice(
					financialIndex,
					1
			  );
		setData(newData);
	};

	const updateRowIndex = (
		financialIndex: number,
		event: React.ChangeEvent<any>
	) => {
		const newData: any = { ...data };
		const subcategory =
			categoryIndex != null
				? newData.categories[categoryIndex].subcategories[
						subcategoryIndex
				  ]
				: newData.subcategories[subcategoryIndex];
		subcategory.pl_financials[financialIndex][event.target.name] =
			getEventValue(event);
		setData(newData);
	};

	const currentTotal = subcategory.pl_financials.reduce(
		(accumulator, pl_financial) => accumulator + pl_financial.value_current,
		0
	);

	const oneYearAgoTotal = subcategory.pl_financials.reduce(
		(accumulator, pl_financial) =>
			accumulator + pl_financial.value_one_year_ago,
		0
	);

	const twoYearsAgoTotal = subcategory.pl_financials.reduce(
		(accumulator, pl_financial) =>
			accumulator + pl_financial.value_two_years_ago,
		0
	);

	const currentNetPct = calculatePercentage(
		currentTotal,
		totalNetRevenues.value_current
	);
	const oneYearAgoNetPct = calculatePercentage(
		oneYearAgoTotal,
		totalNetRevenues.value_one_year_ago
	);
	const twoYearsAgoNetPct = calculatePercentage(
		twoYearsAgoTotal,
		totalNetRevenues.value_two_years_ago
	);

	return (
		<>
			<h3 className='h4'>{subcategory.name}</h3>
			<Table striped bordered hover responsive>
				<thead>
					<tr>
						<th
							id={`${subcategory.name}Num`}
							className='cell-xs sticky-cell'
						>
							#
						</th>
						<th>Note</th>
						<th>{currentYear - 2}</th>
						<th>{currentYear - 1}</th>
						<th>{currentYear}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{subcategory.pl_financials.map(
						(financial, financialIndex) => (
							<tr
								key={`sub-${subcategoryIndex}-row-${financialIndex}`}
							>
								<td className='row-num'>
									{financialIndex + 1}
								</td>
								<td>
									<TextInput
										name='note'
										index={financialIndex}
										value={financial.note}
										onChange={(event) =>
											updateRowIndex(
												financialIndex,
												event
											)
										}
										errors={errors}
										arrayName={arrayName}
										errorArrayName={errorArrayName}
									/>
								</td>
								<td>
									<DecimalInput
										name='value_two_years_ago'
										index={financialIndex}
										value={financial.value_two_years_ago}
										onChange={(event) =>
											updateRowIndex(
												financialIndex,
												event
											)
										}
										errors={errors}
										arrayName={arrayName}
										errorArrayName={errorArrayName}
										negativeValues={true}
									/>
								</td>
								<td>
									<DecimalInput
										name='value_one_year_ago'
										index={financialIndex}
										value={financial.value_one_year_ago}
										onChange={(event) =>
											updateRowIndex(
												financialIndex,
												event
											)
										}
										errors={errors}
										arrayName={arrayName}
										errorArrayName={errorArrayName}
										negativeValues={true}
									/>
								</td>
								<td>
									<DecimalInput
										name='value_current'
										index={financialIndex}
										value={financial.value_current}
										onChange={(event) =>
											updateRowIndex(
												financialIndex,
												event
											)
										}
										errors={errors}
										arrayName={arrayName}
										errorArrayName={errorArrayName}
										negativeValues={true}
									/>
								</td>
								<td className='d-flex justify-content-end'>
									<Button
										variant='outline-primary'
										onClick={(event) =>
											deleteRow(financialIndex)
										}
									>
										Delete
									</Button>
								</td>
							</tr>
						)
					)}
				</tbody>
				<tfoot>
					<tr>
						<th />
						<th>Actual</th>
						<th>{formatCurrency(twoYearsAgoTotal)}</th>
						<th>{formatCurrency(oneYearAgoTotal)}</th>
						<th>{formatCurrency(currentTotal)}</th>
						<th />
					</tr>
					<tr>
						<th />
						<th>% of Net Revenues</th>
						<th>{twoYearsAgoNetPct}</th>
						<th>{oneYearAgoNetPct}</th>
						<th>{currentNetPct}</th>
						<th />
					</tr>
				</tfoot>
			</Table>

			<div className='d-flex justify-content-end'>
				<Button onClick={(event) => addRow()} className='mt-4'>
					Add Row
				</Button>
			</div>
		</>
	);
};

export default SubcategoryTable;
