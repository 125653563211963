import React from 'react';
import { Button, Form } from 'react-bootstrap';
import cn from 'classnames';

import LhCarrier from '../../../Data/LhCarrier';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import { range } from '../../../utils';
import { LhCarrierAdditionalInfo } from '../LhCarriers';
import rowHasErrors from '../Helpers/RowHasErrors';

interface Props {
	currentYear: number;
	lhCarrier: Partial<LhCarrier & LhCarrierAdditionalInfo>;
	index: number;
	errors: Errors & ErrorBag;
	updateOverride: (index: number, year: string, event: React.ChangeEvent<any>) => void;
}

const LhCarrierOverridesRow = ({
	currentYear,
	lhCarrier,
	index,
	errors,
	updateOverride,
}: Props) => {
	const arrayName = 'lhCarrier';
	const errorArrayName = 'lhCarriers';
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td
				id={`lhCarrier-${index + 1}-overrides`}
				className='row-num sticky-cell'
			>
				{index + 1}
			</td>
			<td className='align-middle'>{lhCarrier.carrier_name}</td>
			{range(currentYear - 5, currentYear).map((year: any) => (
				<td key={year}>
					<DecimalInput
						name={`overrides.${year}`}
						index={index}
						value={lhCarrier.overrides?.[year] ?? ''}
						onChange={(event, yearIndex) =>
							updateOverride(index, year, event)
						}
						errors={errors}
						arrayName={arrayName}
						errorArrayName={errorArrayName}
					/>
				</td>
			))}
		</tr>
	);
};

export default LhCarrierOverridesRow;
