import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';

interface Props {
	children: ReactNode;
}

const GuestLayout = (props: Props) => {
	return (
		<Container>{props.children}</Container>
	);
};

export default GuestLayout;
