import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

import LhCarrier from '../../../Data/LhCarrier';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import rowHasErrors from '../Helpers/RowHasErrors';

interface Props {
	annual_total_premium: number;
	annual_total_commission: number;
	lhCarrier: Partial<LhCarrier>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: any, index: number) => void;
	deleteIndex: (index: number) => void;
}

const LhCarrierRow = ({
	annual_total_premium,
	annual_total_commission,
	lhCarrier,
	index,
	errors,
	updateIndex,
	deleteIndex,
}: Props) => {
	const arrayName = 'lhCarrier';
	const errorArrayName = 'lhCarriers';
	let premiumPercent: any = '';
	if (annual_total_premium > 0) {
		premiumPercent = Math.round(
			(100 * (lhCarrier.total_annual_premium ?? 0)) /
				(annual_total_premium > 0 ? annual_total_premium : 1)
		);
	}
	let commissionPercent: any = '';
	if (annual_total_commission > 0) {
		commissionPercent = Math.round(
			(100 * (lhCarrier.annual_agency_commission ?? 0)) /
				(annual_total_commission > 0 ? annual_total_commission : 1)
		);
	}
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`lhCarrier-${index + 1}`} className='row-num sticky-cell'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='carrier_name'
					index={index}
					value={lhCarrier.carrier_name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='total_annual_premium'
					index={index}
					value={lhCarrier.total_annual_premium}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='text-center align-middle fw-bold'>
				{`${premiumPercent} %`}
			</td>
			<td>
				<DecimalInput
					name='annual_agency_commission'
					index={index}
					value={lhCarrier.annual_agency_commission}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='text-center align-middle fw-bold'>
				{`${commissionPercent} %`}
			</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default LhCarrierRow;
