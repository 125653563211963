import React from 'react';
import { Form } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import ReactSelect from 'react-select';

interface Props {
	name: string;
	label?: string;
	value: boolean;
	onChange: (event: any) => void;
	errors: Errors & ErrorBag;
}

const Checkbox = ({
    name,
    label,
    value,
    onChange,
    errors,
}: Props) => {
	return (
		<Form.Group>
            {errors[`${name}`] && (
				<Form.Control.Feedback type='invalid'>
					{errors[`${name}`]}
				</Form.Control.Feedback>
			)}
				<Form.Check
					id={name}
                    type='checkbox'
					label={label}
					checked={value}
					onChange={(event) => onChange(event)}
				/>
		</Form.Group>
	);
};

export default Checkbox;