export enum CorporateStructure {
    c_corp = 'C-Corp',
    s_corp = 'S-Corp',
    llc = 'LLC',
    partnership = 'Partnership',
}

export const getAllCorporateStructures = () => {
    return Object.keys(CorporateStructure).map(
        (val:string) => {
            if(isNaN(Number(val))) {
                return {value: val, label: CorporateStructure[val as keyof typeof CorporateStructure]};
            }
        }
    );
}