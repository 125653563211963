import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table, Alert, Row, Col } from 'react-bootstrap';
import { usePage, useForm } from '@inertiajs/inertia-react';

import Valuation from '../../Data/Valuation';
import PlCategory from '../../Data/PlCategory';
import PlSubcategory from '../../Data/PlSubcategory';
import SubcategoryTable from './PlCategory/SubcategoryTable';
import ProFormaTable from './PlCategory/ProFormaTable';
import AuthedLayout from '../../Layouts/Authed';
import { replaceNullWithEmpty } from './Helpers/RepeatingForms';
import formatCurrency from './Helpers/FormatCurrency';
import calculatePercentage from './Helpers/CalculatePercentage';
import FormHeader from '../../Components/Forms/FormHeader';
import { PlCategoryType } from '../../Data/PlCategoryType';
import ThreeYearBreakdown from '../../Data/ThreeYearBreakdown';
import CategoryTable from './PlCategory/CategoryTable';

interface Props {
	valuation: Valuation;
	categories: CategoryData[];
	currentYear: number;
}

export interface FormData {
	categories: CategoryData[];
}

export interface CategoryData {
	category: PlCategory;
	otherCategoriesNet: ThreeYearBreakdown;
	otherCategoriesProForma: number;
	subcategories: PlSubcategory[];
}

const TotalExpensesForm = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		categories: props.categories.map((category) => {
			return {
				...category,
				subcategories: category.subcategories.map((subcategory) => {
					return {
						...subcategory,
						pl_financials:
							replaceNullWithEmpty(subcategory.pl_financials) ??
							[],
						pl_pro_formas:
							replaceNullWithEmpty(subcategory.pl_pro_formas) ??
							[],
					};
				}),
			};
		}),
	});

	const submit = (event: React.FormEvent<any>) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/total-expenses`, {
			preserveState: false,
		});
	};

	useEffect(() => {
		scrollTo(0, 0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Total Expenses'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				{data.categories.map((categoryData, categoryIndex) => (
					<div key={`category-${categoryIndex}`}>
						<h2 className='text-white bg-navy mt-8 mb-4 p-4'>{categoryData.category.name}</h2>
						<CategoryTable
							category={categoryData.category}
							categoryIndex={categoryIndex}
							currentYear={props.currentYear}
							data={data}
							dataSubcategories={categoryData.subcategories}
							errors={errors}
							otherCategoriesNet={categoryData.otherCategoriesNet}
							otherCategoriesProForma={
								categoryData.otherCategoriesProForma
							}
							setData={setData}
						/>
					</div>
				))}
				<div className='d-flex justify-content-end'>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

TotalExpensesForm.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default TotalExpensesForm;
