import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

import PcCarrier from '../../../Data/PcCarrier';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import rowHasErrors from '../Helpers/RowHasErrors';

interface Props {
	pcCarrier: Partial<PcCarrier>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: any, index: number) => void;
	deleteIndex: (index: number) => void;
	annual_total_premium: number;
	annual_total_commission: number;
}

const PcCarrierRow = ({
	pcCarrier,
	index,
	errors,
	updateIndex,
	deleteIndex,
	annual_total_premium,
	annual_total_commission,
}: Props) => {
	const arrayName = 'pcCarrier';
	const errorArrayName = 'pcCarriers';
	let premiumPercent: any = 0;
	if (annual_total_premium > 0) {
		premiumPercent = Math.round(
			(100 * (pcCarrier.total_annual_premium ?? 0)) /
				(annual_total_premium > 0 ? annual_total_premium : 1)
		);
	}
	let commissionPercent: any = 0;
	if (annual_total_commission > 0) {
		commissionPercent = Math.round(
			(100 * (pcCarrier.annual_agency_commission ?? 0)) /
				(annual_total_commission > 0 ? annual_total_commission : 1)
		);
	}
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`pcCarrier-${index + 1}`} className='row-num sticky-cell'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='carrier_name'
					index={index}
					value={pcCarrier.carrier_name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='total_annual_premium'
					index={index}
					value={pcCarrier.total_annual_premium}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='text-center align-middle fw-bold'>
				{`${premiumPercent}%`}
			</td>
			<td>
				<DecimalInput
					name='annual_agency_commission'
					index={index}
					value={pcCarrier.annual_agency_commission}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='text-center align-middle fw-bold'>
				{`${commissionPercent}%`}
			</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default PcCarrierRow;
