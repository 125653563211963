import React, { useEffect, useState } from 'react';
import { Form, Button, InputGroup, Alert } from 'react-bootstrap';
import cn from 'classnames';
import Employee from '../../../Data/Employee';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import rowHasErrors from '../Helpers/RowHasErrors';
import { enumForSelect } from '../Helpers/EnumForSelect';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';
import ReactSelect from 'react-select';
import { EmployeeRole } from '../../../Data/EmployeeRole';
import { PrimaryFunction } from '../../../Data/PrimaryFunction';
import { Gender } from '../../../Data/Gender';
import { Ethnicity } from '../../../Data/Ethnicity';
import formatCurrency from '../Helpers/FormatCurrency';

interface Props {
	employee: Partial<Employee>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: any, index: number) => void;
	deleteIndex: (index: number) => void;
}

const EmployeeRow = ({
	employee,
	index,
	errors,
	updateIndex,
	deleteIndex,
}: Props) => {
	const totalCompensation =
		(employee?.base_salary || 0) +
		(employee?.commission || 0) +
		(employee?.annual_bonus || 0);
	const arrayName = 'employee';
	const errorArrayName = 'employees';

	// handle select nonsense
	const [roleSelect, setRoleSelect] = useState<{
		label: string;
		value: string;
	}>();
	const [primaryFunctionSelect, setPrimaryFunctionSelect] = useState<{
		label: string;
		value: string;
	}>();
	const [genderSelect, setGenderSelect] = useState<{
		label: string;
		value: string;
	}>();
	const [ethnicitySelect, setEthnicitySelect] = useState<{
		label: string;
		value: string;
	}>();
	const handleRoleChange = (input: any) => {
		employee.role = input.label;
	};
	const handlePrimaryFunctionChange = (input: any) => {
		employee.primary_function = input.label;
	};
	const handleGenderChange = (input: any) => {
		employee.gender = input.label;
	};
	const handleEthnicityChange = (input: any) => {
		employee.ethnicity = input.label;
	};
	const roleOptions = enumForSelect(EmployeeRole) as [];
	const primaryFunctionOptions = enumForSelect(PrimaryFunction) as [];
	const genderOptions = enumForSelect(Gender) as [];
	const ethnicityOptions = enumForSelect(Ethnicity) as [];
	useEffect(() => {
		if (employee.role) {
			setRoleSelect({ label: employee.role, value: employee.role });
		}
		if (employee.primary_function) {
			setPrimaryFunctionSelect({
				label: employee.primary_function,
				value: employee.primary_function,
			});
		}
		if (employee.gender) {
			setGenderSelect({ label: employee.gender, value: employee.gender });
		}
		if (employee.ethnicity) {
			setEthnicitySelect({
				label: employee.ethnicity,
				value: employee.ethnicity,
			});
		}
	}, []);

	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`employee-${index + 1}`} className='row-num sticky-cell'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='first_name'
					index={index}
					value={employee.first_name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='last_name'
					index={index}
					value={employee.last_name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='ams_id'
					index={index}
					value={employee.ams_id}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<ReactSelect
					name='role'
					value={roleSelect}
					options={roleOptions}
					onChange={handleRoleChange}
					className='input'
					menuPosition='fixed'
				/>
				{errors[`employees.${index}.role`] && (
					<Alert variant='dkRed'>
						{errors[`employees.${index}.role`]}
					</Alert>
				)}
			</td>
			<td>
				<ReactSelect
					name='primary_function'
					value={primaryFunctionSelect}
					options={primaryFunctionOptions}
					onChange={handlePrimaryFunctionChange}
					className='input'
					menuPosition='fixed'
				/>
				{errors[`employees.${index}.primary_function`] && (
					<Alert variant='dkRed'>
						{errors[`employees.${index}.primary_function`]}
					</Alert>
				)}
			</td>
			<td>
				<Form.Group>
					<Form.Control
						name='birth_date'
						type='date'
						aria-labelledby={`employee-${index + 1} birth_date`}
						value={employee.birth_date}
						onChange={(event) => updateIndex(event, index)}
						isInvalid={!!errors[`employees.${index}.birth_date`]}
					/>
					{errors[`employees.${index}.birth_date`] && (
						<Form.Control.Feedback type='invalid'>
							{errors[`employees.${index}.birth_date`]}
						</Form.Control.Feedback>
					)}
				</Form.Group>
			</td>
			<td>
				<Form.Group>
					<Form.Control
						name='hire_date'
						type='date'
						aria-labelledby={`employee-${index + 1} hire_date`}
						value={employee.hire_date}
						onChange={(event) => updateIndex(event, index)}
						isInvalid={!!errors[`employees.${index}.hire_date`]}
					/>
					{errors[`employees.${index}.hire_date`] && (
						<Form.Control.Feedback type='invalid'>
							{errors[`employees.${index}.hire_date`]}
						</Form.Control.Feedback>
					)}
				</Form.Group>
			</td>
			<td>
				<DecimalInput
					name='years_of_experience'
					index={index}
					value={employee.years_of_experience}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					prefix={null}
					negativeValues={false}
					digits={0}
				/>
			</td>
			<td>
				<Form.Group>
					<Form.Control
						name='termination_date'
						type='date'
						aria-labelledby={`employee-${
							index + 1
						} termination_date`}
						value={employee.termination_date}
						onChange={(event) => updateIndex(event, index)}
						isInvalid={
							!!errors[`employees.${index}.termination_date`]
						}
					/>
					{errors[`employees.${index}.termination_date`] && (
						<Form.Control.Feedback type='invalid'>
							{errors[`employees.${index}.termination_date`]}
						</Form.Control.Feedback>
					)}
				</Form.Group>
			</td>
			<td>
				<TextInput
					name='termination_reason'
					index={index}
					value={employee.termination_reason}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='avg_weekly_hours_worked'
					index={index}
					value={employee.avg_weekly_hours_worked}
					onChange={(event) => updateIndex(event, index)}
					digits={0}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					negativeValues={false}
					prefix={null}
				/>
			</td>
			<td>
				<DecimalInput
					name='base_salary'
					index={index}
					value={employee.base_salary}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='commission'
					index={index}
					value={employee.commission}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='annual_bonus'
					index={index}
					value={employee.annual_bonus}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='align-middle text-center fw-bold text-dkRed'>
				<span
					aria-labelledby={`employee-${index + 1} total_compensation`}
				>
					{formatCurrency(totalCompensation)}
				</span>
			</td>
			<td>
				<DecimalInput
					name='percent_of_ownership'
					index={index}
					prefix={null}
					postfix='%'
					value={employee.percent_of_ownership}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='designation_1'
					index={index}
					value={employee.designation_1}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='designation_2'
					index={index}
					value={employee.designation_2}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<Form.Group className='d-flex justify-content-center'>
					<Form.Check
						name='perpetuation_plan'
						type='checkbox'
						aria-labelledby={`employee-${
							index + 1
						} perpetuation_plan`}
						checked={employee.perpetuation_plan}
						onChange={(event) => updateIndex(event, index)}
						isInvalid={
							!!errors[`employees.${index}.perpetuation_plan`]
						}
					/>
					{errors[`employees.${index}.perpetuation_plan`] && (
						<Form.Control.Feedback type='invalid'>
							{errors[`employees.${index}.perpetuation_plan`]}
						</Form.Control.Feedback>
					)}
				</Form.Group>
			</td>
			<td>
				<ReactSelect
					name='gender'
					value={genderSelect}
					options={genderOptions}
					onChange={handleGenderChange}
					className='input'
					menuPosition='fixed'
				/>
				{errors[`employees.${index}.gender`] && (
					<Alert variant='dkRed'>
						{errors[`employees.${index}.gender`]}
					</Alert>
				)}
			</td>
			<td>
				<ReactSelect
					name='ethnicity'
					value={ethnicitySelect}
					options={ethnicityOptions}
					onChange={handleEthnicityChange}
					className='input'
					menuPosition='fixed'
				/>
				{errors[`employees.${index}.ethnicity`] && (
					<Alert variant='dkRed'>
						{errors[`employees.${index}.ethnicity`]}
					</Alert>
				)}
			</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default EmployeeRow;
