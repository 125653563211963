import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';

// check if an error object has errors for a specific row index
const rowHasErrors = (
	errors: Errors & ErrorBag,
	index: number,
) => {
    var hasError = false;
	Object.keys(errors).forEach((error) => {
        var split = error.split('.');
        if (parseInt(split[1]) === index) {
            hasError = true;
        }
    });

    return hasError;
};

export default rowHasErrors;
