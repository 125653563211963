import React from 'react';
import { Alert } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';

import PlSubcategory from '../../../Data/PlSubcategory';
import ThreeYearBreakdown from '../../../Data/ThreeYearBreakdown';

import ProFormaTable from './ProFormaTable';
import CategorySummaryTable from './CategorySummaryTable';
import SubcategoryTable from './SubcategoryTable';
import PlCategory from '../../../Data/PlCategory';
import { PlCategoryType } from '../../../Data/PlCategoryType';

interface Props {
	category: PlCategory;
    categoryIndex?: number;
	currentYear: number;
	// data props are read-only. use setData to change these
	data: any;
	dataSubcategories: PlSubcategory[];
	errors: Errors & ErrorBag;
	otherCategoriesNet: ThreeYearBreakdown;
	otherCategoriesProForma: number;
	setData: any;
}

const CategoryTable = (props: Props) => {
	const financialDoubleReduce = (key: keyof Props['otherCategoriesNet']) => {
		return props.dataSubcategories.reduce(
			(subAccumulator, plSubcategory) =>
				subAccumulator +
				plSubcategory.pl_financials.reduce(
					(finAccumulator, plFinancial) =>
						finAccumulator + (plFinancial?.[key] || 0),
					0
				),
			0
		);
	};

	const categoryNetRevenues = {
		value_current: financialDoubleReduce('value_current'),
		value_one_year_ago: financialDoubleReduce('value_one_year_ago'),
		value_two_years_ago: financialDoubleReduce('value_two_years_ago'),
	};

	const calculateNetWithCategory = () => {
		if (props.category.subtracted_from_sums) {
			return {
				value_current:
					props.otherCategoriesNet.value_current -
					categoryNetRevenues.value_current,
				value_one_year_ago:
					props.otherCategoriesNet.value_one_year_ago -
					categoryNetRevenues.value_one_year_ago,
				value_two_years_ago:
					props.otherCategoriesNet.value_two_years_ago -
					categoryNetRevenues.value_two_years_ago,
			};
		}

		return {
			value_current:
				props.otherCategoriesNet.value_current +
				categoryNetRevenues.value_current,
			value_one_year_ago:
				props.otherCategoriesNet.value_one_year_ago +
				categoryNetRevenues.value_one_year_ago,
			value_two_years_ago:
				props.otherCategoriesNet.value_two_years_ago +
				categoryNetRevenues.value_two_years_ago,
		};
	};

	// if category is part of net revenues, we have a partial net and
	// need to add the current category to it
	const totalNetRevenues: ThreeYearBreakdown =
		props.category.type == PlCategoryType.netRevenues
			? calculateNetWithCategory()
			: props.otherCategoriesNet;

	const categoryISA = props.dataSubcategories.reduce(
		(subAccumulator, plSubcategory) =>
			subAccumulator +
			plSubcategory.pl_pro_formas.reduce(
				(finAccumulator, plProForma) =>
					finAccumulator + (plProForma.value || 0),
				0
			),
		0
	);

	const categoryProForma = categoryNetRevenues.value_current + categoryISA;
	const netProForma =
		props.category.type == PlCategoryType.netRevenues
			? props.category.subtracted_from_sums
				? props.otherCategoriesProForma - categoryProForma
				: props.otherCategoriesProForma + categoryProForma
			: props.otherCategoriesProForma;

	return (
		<>
			{props.category.subtracted_from_sums && (
				<Alert variant={'danger'}>
					Values in this category are{' '}
					<span className='fw-bold'>subtracted</span> from sums,
					rather than added.
				</Alert>
			)}

			{props.dataSubcategories.map((subcategory, subcategoryIndex) => (
				<div key={`subcategory-${subcategoryIndex}`}>
					<SubcategoryTable
                        categoryIndex={props.categoryIndex}
						subcategory={subcategory}
						subcategoryIndex={subcategoryIndex}
						errors={props.errors}
						currentYear={props.currentYear}
						data={props.data}
						setData={props.setData}
						totalNetRevenues={totalNetRevenues}
					/>

					<ProFormaTable
                        categoryIndex={props.categoryIndex}
						subcategory={subcategory}
						subcategoryIndex={subcategoryIndex}
						errors={props.errors}
						data={props.data}
						setData={props.setData}
						totalAdjustedNetRevenues={netProForma}
					/>
				</div>
			))}

			<hr />

			<CategorySummaryTable
				categoryISA={categoryISA}
				categoryNetRevenues={categoryNetRevenues}
				categoryProForma={categoryProForma}
				currentYear={props.currentYear}
				netProForma={netProForma}
				totalNetRevenues={totalNetRevenues}
			/>
		</>
	);
};

export default CategoryTable;
