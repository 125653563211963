export enum EmployeeRole {
    csr = 'CSR',
    csrPersonalLines = 'CSR - Personal Lines',
    csrCommercialLines = 'CSR - Commercial Lines',
    producer = 'Producer',
    producerPersonalLines = 'Producer - Personal Lines',
    producerCommercialLines = 'Producer - Commercial Lines',
    ceoPresident = 'CEO / President',
    operations = 'Operations',
    accountingFinance = 'Accounting / Finance',
    other = 'Other',
}