import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import cn from 'classnames';

import NonRecurringRevenue from '../../../Data/NonRecurringRevenue';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import rowHasErrors from '../Helpers/RowHasErrors';
import TextInput from '../../../Components/Forms/TextInput';
import DecimalInput from '../../../Components/Forms/DecimalInput';

interface Props {
	non_recurring_revenue: Partial<NonRecurringRevenue>;
	index: number;
	errors: Errors & ErrorBag;
	updateIndex: (event: any, index: number) => void;
	deleteIndex: (index: number) => void;
}

const NonRecurringRevenueRow = ({
	non_recurring_revenue,
	index,
	errors,
	updateIndex,
	deleteIndex,
}: Props) => {
	const arrayName = 'non_recurring_revenue';
	const errorArrayName = 'non_recurring_revenues';
	return (
		<tr
			className={cn({
				'table-danger': rowHasErrors(errors, index),
			})}
		>
			<td id={`non_recurring_revenue-${index + 1}`} className='row-num'>
				{index + 1}
			</td>
			<td>
				<TextInput
					name='account_name'
					index={index}
					value={non_recurring_revenue.account_name}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<TextInput
					name='industry'
					index={index}
					value={non_recurring_revenue.industry}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='total_agency_commissions_booked'
					index={index}
					value={
						non_recurring_revenue.total_agency_commissions_booked
					}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td>
				<DecimalInput
					name='num_years_with_agency'
					index={index}
					value={non_recurring_revenue.num_years_with_agency}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
					prefix={null}
					negativeValues={false}
					digits={0}
				/>
			</td>
			<td>
				<TextInput
					name='agency_producer'
					index={index}
					value={non_recurring_revenue.agency_producer}
					onChange={(event) => updateIndex(event, index)}
					errors={errors}
					arrayName={arrayName}
					errorArrayName={errorArrayName}
				/>
			</td>
			<td className='d-flex justify-content-end'>
				<Button
					variant='outline-primary'
					onClick={(event) => deleteIndex(index)}
				>
					Delete
				</Button>
			</td>
		</tr>
	);
};

export default NonRecurringRevenueRow;
