import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import NonRecurringExpense from '../../Data/NonRecurringExpense';
import Valuation from '../../Data/Valuation';
import NonRecurringExpenseRow from './NonRecurringExpenses/NonRecurringExpenseRow';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import FormHeader from '../../Components/Forms/FormHeader';
import formatCurrency from './Helpers/FormatCurrency';


interface Props {
	valuation: Valuation;
	non_recurring_expenses: NonRecurringExpense[];
}

interface FormData {
	non_recurring_expenses: Partial<NonRecurringExpense>[];
}

const NonRecurringExpenses = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post } = useForm<FormData>({
		non_recurring_expenses:
			replaceNullWithEmpty(props.non_recurring_expenses) ?? [],
	});

	useEffect(() => {
		if (data.non_recurring_expenses.length == 0) {
			addRow();
		}
	}, [props.non_recurring_expenses]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/non-recurring-expenses`);
	};

	const addRow = () => {
		setData({
			...data,
			non_recurring_expenses: [
				...data.non_recurring_expenses,
				{
					id: '',
					expense: '',
					p_l_line_item: '',
					total_expense_dollars_booked: '',
					num_years_with_agency: '',
					description_reason: '',
				},
			] as NonRecurringExpense[],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData: any = { ...data };
		newData.non_recurring_expenses[index][event.target.name] =
			getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData: any = { ...data };
		newData.non_recurring_expenses.splice(index, 1);
		setData(newData);
	};

	const totalExpenseDollars = formatCurrency(
		data.non_recurring_expenses.reduce(
			(accumulator, currentValue) =>
				accumulator + (currentValue?.total_expense_dollars_booked || 0),
			0
		)
	);

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Non-recurring Expenses'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='non_recurring_expensesNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='expense'>Expense</th>
							<th id='p_l_line_item'>P&L Line Item</th>
							<th
								id='total_expense_dollars_booked'
								className='cell-lg'
							>
								Total Expense $'s Booked for TTM
							</th>
							<th id='num_years_with_agency'>
								# Years with Agency
							</th>
							<th id='description_reason'>
								Description / Reason it is Non-recurring
							</th>
							<th id='delete_element' className='cell-sm'></th>
						</tr>
					</thead>
					<tbody>
						{data.non_recurring_expenses.map(
							(
								non_recurring_expense: Partial<NonRecurringExpense>,
								index: number
							) => (
								<NonRecurringExpenseRow
									key={`non_recurring_expenses-${index}`}
									non_recurring_expense={
										non_recurring_expense
									}
									index={index}
									errors={errors}
									updateIndex={updateIndex}
									deleteIndex={deleteIndex}
								/>
							)
						)}
					</tbody>
					<tfoot>
						<tr>
							<th />
							<th />
							<th />
							<th>{totalExpenseDollars}</th>
							<th />
							<th />
						</tr>
					</tfoot>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button onClick={addRow} className='mt-4'>
						Add Row
					</Button>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

NonRecurringExpenses.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default NonRecurringExpenses;
