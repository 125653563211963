import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table, Alert } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import ProducerBookOfBusiness from '../../Data/ProducerBookOfBusiness';
import Valuation from '../../Data/Valuation';
import ProducerBobRow from './ProducerBooksOfBusiness/ProducerBobRow';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import FormHeader from '../../Components/Forms/FormHeader';

interface Props {
	valuation: Valuation;
	producer_bobs: ProducerBookOfBusiness[];
}

interface FormData {
	producer_bobs: Partial<ProducerBookOfBusiness>[];
}

const ProducerBooksOfBusiness = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post } = useForm<FormData>({
		producer_bobs: replaceNullWithEmpty(props.producer_bobs) ?? [],
	});

	useEffect(() => {
		if (data.producer_bobs.length == 0) {
			addRow();
		}
	}, [props.producer_bobs]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/producer-bobs`);
	};

	const addRow = () => {
		setData({
			...data,
			producer_bobs: [
				...data.producer_bobs,
				{
					id: '',
					name: '',
					employee_benefits_total_book: '',
					p_c_total_book: '',
					producer_age: '',
					compensation_structure_splits: '',
				},
			] as ProducerBookOfBusiness[],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData: any = { ...data };
		newData.producer_bobs[index][event.target.name] = getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData: any = { ...data };
		newData.producer_bobs.splice(index, 1);
		setData(newData);
	};

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Ownership and Shares'
			/>
			<Alert variant='dkRed'>
				Please use Total Commissions & Fees for Book of Business Numbers
			</Alert>
			<Form onSubmit={submit} className='valuationForm'>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='producer_bobNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='name'>Producer Name</th>
							<th id='employee_benefits_total_book'>
								Employee Benefits Total Book $
							</th>
							<th id='p_c_total_book'>P&C Total Book $</th>
							<th id='producer_age'>Producer Age</th>
							<th id='compensation_structure_splits'>
								Compensation Structure Splits
							</th>
							<th id='delete_element'></th>
						</tr>
					</thead>
					<tbody>
						{data.producer_bobs.map(
							(
								producer_bob: Partial<ProducerBookOfBusiness>,
								index: number
							) => (
								<ProducerBobRow
									key={`producer_bob-${index}`}
									producer_bob={producer_bob}
									index={index}
									errors={errors}
									updateIndex={updateIndex}
									deleteIndex={deleteIndex}
								/>
							)
						)}
					</tbody>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button onClick={addRow} className='mt-4'>
						Add Row
					</Button>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

ProducerBooksOfBusiness.layout = (page: ReactNode) => (
	<AuthedLayout>{page}</AuthedLayout>
);

export default ProducerBooksOfBusiness;
