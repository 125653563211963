import React, { ReactNode, useEffect } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import { useForm, usePage } from '@inertiajs/inertia-react';

import AuthedLayout from '../../Layouts/Authed';
import Valuation from '../../Data/Valuation';
import Employee from '../../Data/Employee';
import EmployeeRow from './Employees/EmployeeRow';
import { getEventValue, replaceNullWithEmpty } from './Helpers/RepeatingForms';
import FormHeader from '../../Components/Forms/FormHeader';
import formatCurrency from './Helpers/FormatCurrency';

interface Props {
	valuation: Valuation;
	employees: Employee[];
}

interface FormData {
	employees: Partial<Employee>[];
}

const Employees = (props: Props) => {
	const { errors } = usePage().props;

	const { data, setData, post, processing } = useForm<FormData>({
		employees: replaceNullWithEmpty(props.employees) ?? [],
	});

	useEffect(() => {
		if (data.employees.length == 0) {
			addRow();
		}
	}, [props.employees]);

	const submit = (event: any) => {
		event.preventDefault();
		post(`/valuation-form/${props.valuation.id}/employees`);
	};

	const addRow = () => {
		setData({
			...data,
			employees: [
				...data.employees,
				{
					id: '',
					first_name: '',
					last_name: '',
					ams_id: '',
					role: '',
					primary_function: '',
					birth_date: '',
					hire_date: '',
					years_of_experience: '',
					termination_date: '',
					termination_reason: '',
					avg_weekly_hours_worked: '',
					base_salary: '',
					commission: '',
					annual_bonus: '',
					percent_of_ownership: '',
					designation_1: '',
					designation_2: '',
					perpetuation_plan: false,
					gender: '',
					ethnicity: '',
				},
			] as Employee[],
		});
	};

	const updateIndex = (event: React.ChangeEvent<any>, index: number) => {
		const newData: any = { ...data };
		newData.employees[index][event.target.name] = getEventValue(event);
		setData(newData);
	};

	const deleteIndex = (index: number) => {
		const newData: any = { ...data };
		newData.employees.splice(index, 1);
		setData(newData);
	};

	const totalBaseSalary = formatCurrency(
		data.employees.reduce(
			(accumulator, currentValue) =>
				accumulator + (currentValue?.base_salary || 0),
			0
		)
	);

	const totalCommission = formatCurrency(
		data.employees.reduce(
			(accumulator, currentValue) =>
				accumulator + (currentValue?.commission || 0),
			0
		)
	);

	const totalAnnualBonus = formatCurrency(
		data.employees.reduce(
			(accumulator, currentValue) =>
				accumulator + (currentValue?.annual_bonus || 0),
			0
		)
	);

	const totalTotalCompensation = formatCurrency(
		data.employees.reduce(
			(accumulator, currentValue) =>
				accumulator +
				(currentValue?.base_salary || 0) +
				(currentValue?.commission || 0) +
				(currentValue?.annual_bonus || 0),
			0
		)
	);

	useEffect(() => {
		scrollTo(0,0);
	}, []);

	return (
		<div>
			<FormHeader
				agencyName={props.valuation.agency_name}
				effectiveDate={props.valuation.effective_date}
				pageName='Employees'
			/>
			<Form onSubmit={submit} className='valuationForm'>
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th
								id='employeeNum'
								className='cell-xs sticky-cell'
							>
								#
							</th>
							<th id='first_name'>First Name</th>
							<th id='last_name'>Last Name</th>
							<th id='ams_id'>AMS ID</th>
							<th id='role'>Role</th>
							<th id='primary_function'>Primary Function</th>
							<th id='birth_date'>Birth Date</th>
							<th id='hire_date'>Hire Date</th>
							<th id='years_of_experience'>
								Years of Experience
							</th>
							<th id='termination_date'>Termination Date</th>
							<th id='termination_reason'>Termination reason</th>
							<th id='avg_weekly_hours_worked'>
								Avg Hours Worked Per Week
							</th>
							<th id='base_salary'>Base Salary</th>
							<th id='commission'>Commission</th>
							<th id='annual_bonus'>Annual Bonus</th>
							<th id='total_compensation'>Total Compensation</th>
							<th id='percent_of_ownership' className='cell-lg'>
								Percent of Ownership
							</th>
							<th id='designation_1'>Designation 1</th>
							<th id='designation_2'>Designation 2</th>
							<th id='perpetuation_plan'>Perpetuation Plan</th>
							<th id='gender'>Gender</th>
							<th id='ethnicity'>Race/Ethnicity</th>
							<th id='delete_element'></th>
						</tr>
					</thead>
					<tbody>
						{data.employees.map(
							(employee: Partial<Employee>, index: number) => (
								<EmployeeRow
									key={`employee-${index}`}
									employee={employee}
									index={index}
									errors={errors}
									updateIndex={updateIndex}
									deleteIndex={deleteIndex}
								/>
							)
						)}
					</tbody>
					<tfoot>
						<tr>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th>{totalBaseSalary}</th>
							<th>{totalCommission}</th>
							<th>{totalAnnualBonus}</th>
							<th>{totalTotalCompensation}</th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</tfoot>
				</Table>

				<div className='d-flex justify-content-end'>
					<Button onClick={addRow} className='mt-4'>
						Add Row
					</Button>
					<Button type='submit' className='mt-4 ms-4'>
						Next
					</Button>
				</div>
			</Form>
		</div>
	);
};

Employees.layout = (page: ReactNode) => <AuthedLayout>{page}</AuthedLayout>;

export default Employees;
