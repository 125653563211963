import React from 'react';
import { Form } from 'react-bootstrap';
import { Errors, ErrorBag } from '@inertiajs/inertia/types/types';
import ReactSelect from 'react-select';

interface Props {
	name: string;
	label?: string;
	value: boolean | null | '';
	onChange: (event: any) => void;
	errors: Errors & ErrorBag;
}

const YesNoSelect = ({
	name,
	label,
	value,
	onChange,
	errors,
}: Props) => {
    let selectValue = null;
    if(value !== '' && value !== null) {
        if(value === true) {
            selectValue = {label: 'Yes', value: true};
        } else {
            selectValue = {label: 'No', value: false};
        }
    }

	return (
		<Form.Group>
			{label && <Form.Label>{label}</Form.Label>}
			{errors[`${name}`] && (
				<Form.Control.Feedback type='invalid'>
					{errors[`${name}`]}
				</Form.Control.Feedback>
			)}
			<ReactSelect
				name={name}
				options={[
					{ label: 'Yes', value: true },
					{ label: 'No', value: false },
				]}
				onChange={(event) => onChange(event)}
				value={selectValue}
				className='input'
			/>
		</Form.Group>
	);
};

export default YesNoSelect;
