// format decimal values for number inputs onBlur
const formatCurrency = (
	number: number,
	forceNegative: boolean = false
) => {
	let modifiedNumber = number;
	if (forceNegative) {
		modifiedNumber = -Math.abs(modifiedNumber);
	}
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		currencySign: 'accounting',
	}).format(modifiedNumber);
};

export default formatCurrency;
